import React, { useState, useEffect }  from 'react';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getTranslations } from "../redux/selectors";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function calcPaths(match, translations) {
  let destinations = [];
  const matches = match.url.split('/');
  matches.shift();

  if (matches[0] === '') {
    destinations.unshift('/');
  } else {
    destinations = matches;
    destinations.unshift('/');
  }

  const paths = destinations.map((el, i, arr) => {
    if (i === 0) {
      return {
        destination: el,
        url: el
      };
    }
    return {
      destination: el,
      url: '/' + arr.slice(1, i + 1).join('/')
    };
  });

  return paths.map((el) => {
    const found = translations.find(obj => obj.text === el.destination);
    return found ? {
      destination: translations.find(obj => obj.text === el.destination).translation,
      url: el.url
    } : null
  }).filter(el => el !== null);
}

function Breadcrumbs(props) {
  const { match } = props;
  const [ paths, setPaths ] = useState([{destinationName: 'Начало', path: '/'}]);

  useEffect(() => {
    if (match !== null) {
      setPaths(calcPaths(match, props.translations));
    }
  }, [match, props.translations]);

  return (
    <Container>
      <Row>
        <Col lg={9}>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb" vocab="https://schema.org/" typeof="BreadcrumbList">
              {
                paths.length === 1 ? null :
                  paths.map((el, i, arr) => {
                    return (
                      <li key={`breadcrumb-link-${i}`} className="breadcrumb-item" property="itemListElement" typeof="ListItem">
                        {
                          i === arr.length - 1 ?
                            <React.Fragment>
                              <span property="name">{el.destination}</span>
                              <meta property="position" content={i + 1}></meta>
                            </React.Fragment>
                            :
                            <React.Fragment>
                              <Link to={el.url} property="item" typeof="WebPage">
                                <span property="name">{el.destination}</span>
                              </Link>
                              <meta property="position" content={i + 1}></meta>
                            </React.Fragment>
                        }
                      </li>
                    )
                  })
              }
            </ol>
          </nav>
        </Col>
      </Row>
    </Container>
  )
}

const mapStateToProps = state => {
  const translations = getTranslations(state);
  return { translations };
};

export default connect(
  mapStateToProps
)(Breadcrumbs);