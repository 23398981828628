import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Pagination from 'react-bootstrap/Pagination';

import '../styles/pagination.css';

function Paginate({ children, elementsPerFirstPage, elementsPerPage, pageItems, className, setActivePage }) {
  let history = useHistory();
  const [ currentPageIndex, setCurrentPageIndex ] = useState(0);
  const [ pages, setPages ] = useState([]);
  const [ currentItems, setCurrentItems ] = useState();
  useEffect(() => {
    if (children) {
      const pages = customChunk(children, elementsPerFirstPage, elementsPerPage);
      const numbers = pages.map((el, index) => index + 1);
      const itemsPages = _.chunk(numbers, pageItems);
      setPages(pages);
      setCurrentItems(itemsPages);
      if (setActivePage) {
        const index = itemsPages.findIndex(itemPage => itemPage.includes(setActivePage));
        if (index !== -1) setCurrentPageIndex(index);
      }
    }
  }, [children, elementsPerFirstPage, elementsPerPage, setActivePage, pageItems]);

  const handleItem = (e) => {
    const item = parseInt(e.currentTarget.getAttribute('data-item'), 10);
    history.push(`/blog/page/${item}`);
  }

  const handleNext = () => history.push(`/blog/page/${setActivePage + 1}`)
  const handlePrev = () => history.push(`/blog/page/${setActivePage - 1}`)
  const handleFirst = () => history.push(`/blog/page/1`)
  const handleLast = () => history.push(`/blog/page/${pages.length}`)

  const customChunk = (arr, elementsPerFirstPage, elementsPerPage) => {
    const firstPage = _.slice(arr, 0, elementsPerFirstPage);
    const pages = _.chunk(_.slice(arr, elementsPerFirstPage), elementsPerPage);
    pages.unshift(firstPage);
    return pages;
  }

  return (
    <div className={className}>
      <Row>
        {
          pages && _.chunk(pages[setActivePage - 1], 3).map((cards, index) => {
            return (
              <React.Fragment key={index}>
                {cards}
              </React.Fragment>
            )
          })
        }
      </Row>
      <Row>
        <Col>
          <Pagination className="justify-content-center">
            <Pagination.First  disabled={setActivePage === 1} onClick={handleFirst} />
            <Pagination.Prev as={Link} to={`/blog/page/${setActivePage - 1}`} disabled={setActivePage === 1} onClick={handlePrev} />
            {
              currentItems && currentItems[currentPageIndex].map((item, index) => {
                return (
                  <Pagination.Item as={Link} to={`/blog/page/${item}`} active={setActivePage === item} key={index} data-item={item} onClick={handleItem}>
                    {item}
                  </Pagination.Item>
                )
              })
            }
            <Pagination.Next as={Link} to={`/blog/page/${setActivePage + 1}`} disabled={setActivePage === pages.length} onClick={handleNext} />
            <Pagination.Last disabled={setActivePage === pages.length} onClick={handleLast}/>
          </Pagination>
        </Col>
      </Row>
    </div>
  )
}

export default Paginate;