import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, Element } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';

const sectionTitles = [
  'Информация относно дружеството, което обработва данните Ви',
  'Информация относно компетентния надзорен орган защита на личните данни',
  'Основание за събиране, обработване и съхраняване на вашите лични данни',
  'Цели и принципи при събирането, обработването и съхраняването на вашите лични данни',
  'Какви видове лични данни събира, обработва и съхранява нашето дружество',
  'Срок на съхранение на личните Ви данни',
  'Предаване на Вашите лични данни за обработване',
  'Вашите права при събирането, обработването и съхранението на личните Ви данни',
  'Право на достъп',
  'Право на коригиране или попълване',
  'Право на изтриване („да бъдеш забравен“)',
  'Право на ограничаване',
  'Право на преносимост',
  'Право на получаване на информация',
  'Право на възражение',
  'Вашите права при нарушение на сигурността на личните ви данни',
  'Лица, на които се предоставят личните ви данни',
];

function WrapChunks({ children }) {
  return React.Children.map(children, (el, index) => {
    return (
      <Element id={`section-${index}`} name={`section-${index}`} className="reading-section">
        <a href={`#section-${index}`}>
          <FontAwesomeIcon icon={['fas', 'link']} />
        </a>
        {el}
      </Element>
    )
  })
}

function PrivacyPolicy({ match }) {
  return (
    <Container className="page-p" id="privacy-policy">
      <Helmet>
        <title>БГ Плакат | Политика за поверителност</title>
        <meta name="description" content="Задължителна информация за правата на лицата по защита на личните данни." />
        <link rel="canonical" href={`https://www.bgplakat.com${match.path}`} />
      </Helmet>
      <Row>
        <Col lg={3} className="d-none d-lg-block">
          <div className="side-nav">
            {
              sectionTitles.map((title, index) => {
                return (
                  <Nav.Link key={index} as={Link} title={title} activeClass="active" to={`section-${index}`} offset={-10} spy={true} smooth={true} duration={250}>{title}</Nav.Link>
                )
              })
            }
          </div>
        </Col>
        <Col lg={9}>
          <div>
            <h1 className="text-primary text-center font-weight-bold mb-4">Задължителна информация за правата на лицата по защита на личните данни</h1>

            <WrapChunks>
              <div className="mb-3">
                <h2>Информация относно дружеството, което обработва данните Ви</h2>
                Наименование: БГ Плакат ЕООД<br/>
                ЕИК/БУЛСТАТ: BG202735876<br/>
                МОЛ: Иван Александров Василев<br/>
                Седалище и адрес на управление: гр. София, ул. Бигла №25<br/>
                Адрес за кореспонденция: гр. София, ул. Митрополит Панарет №16<br/>
                E-mail: office@bgplakat.com<br/>
                Уебсайт: https://bgplakat.com/
              </div>

              <div className="mb-3">
                <h2>Информация относно компетентния надзорен орган защита на личните данни</h2>
                Наименование: Комисия за защита на личните данни<br/>
                Седалище и адрес на управление: гр. София 1592, бул. „Проф. Цветан Лазаров” № 2<br/>
                Адрес за кореспонденция: гр. София 1592, бул. „Проф. Цветан Лазаров” № 2<br/>
                Телефон: 02 915 3 518<br/>
                Интернет страница: www.cpdp.bg
                <hr />
                <p>“БГ Плакат” ЕООД (Наричано по-долу за краткост “Администратор” или “Дружеството”) осъществява дейността си в съответствие със Закона за защита на личните данни и Регламент (ЕС) 2016/679 на Европейския парламент и на Съвета от 01 октомври 2015 година относно защитата на физическите лица във връзка с обработването на лични данни и относно свободното движение на такива данни. Настоящата информация има цел да ви информира за всички аспекти на обработката личните ви данни от Дружеството и правата, които имате във връзка с тази обработка.</p>
                <hr />
              </div>

              <div>
                <h2>Основание за събиране, обработване и съхраняване на вашите лични данни</h2>
                <p>Чл. 1. Администраторът събира и обработва Вашите лични данни във връзка с използването на уебсайта https://bgplakat.com, сключване на договори с дружеството на основание чл. 6, ал. 1, Регламент (ЕС) 2016/679 (GDPR), и по-конкретно въз основа на следното основание:</p>
                <ul>
                  <li>Изрично получено съгласие от Вас като клиент;</li>
                  <li>Изпълнение на задълженията на Администратора по договор с Вас;</li>
                  <li>Спазване на законово задължение, което се прилага спрямо Администратора;</li>
                  <li>За целите на легитимните интереси на Администратора или на трета страна.</li>
                </ul>
              </div>

              <div>
                <h2>Цели и принципи при събирането, обработването и съхраняването на вашите лични данни</h2>
                <p>Чл. 2. (1) Ние събираме и обработваме личните данни, които Вие ни предоставяте във връзка с използването на използването на уебсайта и https://bgplakat.com и сключване на договор с дружеството, включително за следните цели:</p>
                <ul>
                  <li>създаване на профил и предоставяне на пълна функционалност при използването на системата за отчетност;</li>
                  <li>извършване на поръчки и закупуване на продукти и услуги;</li>
                  <li>индивидуализация на страна по договора;</li>
                  <li>счетоводни цели;</li>
                  <li>статистически цели;</li>
                  <li>защита на информационната сигурност;</li>
                  <li>обезпечаване на изпълнението на договора за предоставяне на съответната услуга;</li>
                  <li>участие в събития, организирани от дружеството;</li>
                  <li>участие в провеждане на игри, томболи, рекламни кампании;</li>
                  <li>изпращане на информационен бюлетин при изразено от Вас желание.</li>
                </ul>
                <p>(2) Ние спазваме следните принципи при обработката на Вашите лични данни:</p>
                <ul>
                  <li>законосъобразност, добросъвестност и прозрачност;</li>
                  <li>ограничение на целите на обработване;</li>
                  <li>съотносимост с целите на обработката и свеждане до минимум на събираните данни;</li>
                  <li>точност и актуалност на данните;</li>
                  <li>ограничение на съхранението с оглед постигане на целите;</li>
                  <li>цялостност и поверителност на обработването и гарантиране на подходящо ниво на сигурност на личните данни</li>
                </ul>
                <p>(3) При обработването и съхранението на личните данни, Администраторът може да обработва и съхранява личните данни с цел защита следните си легитимни интереси:</p>
                <ul>
                  <li>изпълнение на задълженията си към Национална агенция по приходите, Министерство на вътрешните работи и други държавни и общински органи.</li>
                </ul>
              </div>

              <div>
                <h2>Какви видове лични данни събира, обработва и съхранява нашето дружество</h2>
                <p>Чл. 3. Дружеството извършва следните операции с предоставените от Вас лични данни за следните цели:</p>
                <ul>
                  <li>Сключване и изпълнение на търговска сделка или договор с клиент – целта на тази операция е сключване и изпълнение на договор с търговски партньор или клиент и неговото администриране. В отделни случаи целта на операцията може да бъде и защита на законните интереси на дружеството по изпълнение на сделката. Предвид ограничения обхват на събираните лични данни и обстоятелството, че част от тях се събират от публично достъпни източници, провеждането на оценка на въздействието не е необходимо извършването на оценка на въздействието на операцията.</li>
                  <li>Регистрация на потребител в системата за отчетност и изпълнение на договор за покупко-продажба – целта на тази операция е създаване на профил за използване на системата за отчетност и получаване на бюлетини при изразено желание. Предоставяне на данни за контакт за извършване на доставка на закупени стоки. Предвид ограничения обхват на събираните лични данни, провеждането на оценка на въздействието не е необходимо извършването на оценка на въздействието на операцията.</li>
                  <li>Изпращане на информационен бюлетин (нюзлетър) – целта на тази операция е администриране на процеса по изпращане на бюлетини до клиентите, които са заявили, че желаят да получават. Предвид ограничения обхват на събираните лични данни, провеждането на оценка на въздействието не е необходимо извършването на оценка на въздействието на операцията.</li>
                  <li>Упражняване право на отказ или извършване на рекламация – целта на тази операция е администриране на процеса по изпращане на закупени стоки до клиентите. Предвид ограничения обхват на събираните лични данни, провеждането на оценка на въздействието не е необходимо извършването на оценка на въздействието на операцията.</li>
                  <li>Регистрация на участник в събития и изпращане на информация или награди – целта на тази операция е администриране на процеса по регистрация на участници в събития и игри, и изпращане на награди от проведени игри. Предвид ограничения обхват на събираните лични данни, провеждането на оценка на въздействието не е необходимо извършването на оценка на въздействието на операцията.</li>
                  <li>Заявка за извършване на услуга, която не е в наличност – целта на тази операция е установяване на контакт с физическото лице с цел заявка за доставка на продукт, който в момента не е в наличност. Предвид ограничения обхват на събираните лични данни, провеждането на оценка на въздействието не е необходимо извършването на оценка на въздействието на операцията.</li>
                </ul>
                <p>Чл. 4. (1) Администраторът обработва следните категории лични данни и информация за следните цели и на следните основания:</p>
                <ul>
                  <li>Данни за регистрация и получаване на бюлетин (имена, еmail)
                    <ul>
                      <li>Цел, за която се събират данните:
                        <ol>
                          <li>Осъществяване на връзка с потребителя и изпращане на информация към него,</li>
                          <li>за целите на регистрация на потребител в системата за отчет, както и</li>
                          <li>за изпращане на информационен бюлетин.</li>
                        </ol>
                      </li>
                      <li>Основание за обработка на личните Ви данни – С приемането на общите условия и регистрация в сайта или извършване на поръчка без регистрация, или при сключването на писмен договор, между Администратора и Вас се създава договорно отношение, на което основание обработваме Вашите лични данни – чл. 6, ал. 1, б. (б) GDPR. Данните Ви за изпращане на информационен бюлетин се обработват на основание дадено от Вас изрично съгласие – чл. 6, ал. 1, б. (а) GDPR.</li>
                    </ul>
                  </li>
                  <li>Данни за получаване на бюлетин (имена, е-mail)
                    <ul>
                      <li>Цел, за която се събират данните:
                        <ol>
                          <li>За изпращане на информационен бюлетин.</li>
                        </ol>
                      </li>
                      <li>Основание за обработка на личните Ви данни – Данните Ви за изпращане на информационен бюлетин се обработват на основание дадено от Вас изрично съгласие – чл. 6, ал. 1, б. (а) GDPR.</li>
                    </ul>
                  </li>
                  <li>Данни за извършване на доставка (имена, телефон, e-mail, адрес)
                    <ul>
                      <li>Цел, за която се събират данните: Изпълнение на задължения на администратора по договор за покупко-продажба от разстояние и доставка на закупените стоки, включително при упражняване на право на връщане и замяна или отказ от закупена стока.</li>
                      <li>Основание за обработка на личните Ви данни – С приемането на общите условия и регистрация в електронния магазин или извършване на поръчка без регистрация, или при сключването на писмен договор, между Администратора и Вас се създава договорно отношение, на което основание обработваме Вашите лични данни – чл. 6, ал. 1, б. (б) GDPR.</li>
                    </ul>
                  </li>
                  <li>Данни от профилите Ви в социални мрежи (публично-достъпната информация от профилите Ви във Facebook, Instagram, LinkedIn)
                    <ul>
                      <li>Цел, за която се събират данните:
                        <ol>
                          <li>Осъществяване на връзка с потребителя и изпращане на информация към него,</li>
                          <li>за целите на регистрация за участие в игра, томбола, кампания и др.</li>
                        </ol>
                      </li>
                      <li>Основание за обработка на личните Ви данни – Данните Ви за записване в нашите събития, игри, кампании и др. се обработват на основание дадено от Вас изрично съгласие – чл. 6, ал. 1, б. (а) GDPR</li>
                    </ul>
                  </li>
                </ul>
                <p>(2) Администраторът не събира и не обработва лични данни, които се отнасят за следното:</p>
                <ul>
                  <li>разкриват расов или етнически произход;</li>
                  <li>разкриват политически, религиозни или философски убеждения, или членство в синдикални организации;</li>
                  <li>генетични и биометрични данни, данни за здравословното състояние или данни за сексуалния живот или сексуалната ориентация.</li>
                </ul>
                <p>(3) Личните данни са събрани от Администратора от лицата, за които се отнасят.</p>
                <p>(4) Дружеството не извършва автоматизирано взимане на решения с данни.</p>
                <p>(5) Дружеството не събира и обработва данни за лица под 16 години, освен с изричното съгласие на техните родители или законни представители.</p>
              </div>

              <div>
                <h2>Срок на съхранение на личните Ви данни</h2>
                <p>Чл. 5. (1) Администраторът съхранява Вашите лични данни за срок не по-дълъг от съществуването на профила Ви в системата за отчет или до оттегляне на съгласието за обработване. След заличаване на профила Ви или успешно приключване, Администраторът полага необходимите грижи да изтрие и унищожи всички Ваши данни, без ненужно забавяне или да ги анонимизира (т.е. да ги приведе във вид, който не разкрива вашата личност).</p>
                <p>(2) Администраторът съхранява Вашите лични данни, предоставени във връзка с направени поръчки, за срок от 5 години за целите на защита на правните интереси на Администратора при съдебни или административни спорове с потребители на системата за отчет, като счетоводните документи се съхраняват за съответния законоустановен срок.</p>
                <p>(3) Администраторът Ви уведомява, в случай, че срокът за съхранение на данните е необходим да бъде удължен с оглед изпълнение на нормативно задължение или с оглед легитимни интереси на Администратора или друго.</p>
                <p>Чл. 6. Администраторът съхранява личните данни на законните представители на търговските си партньори за срока на изпълнение на договора, за спазване на легитимните интереси и законови задължения на Администратора, като този срок може да надхвърля срока на сключения договор.</p>
              </div>

              <div>
                <h2>Предаване на Вашите лични данни за обработване</h2>
                <p>Чл. 7. (1) Администраторът може по собствена преценка да предава част или всички Ваши лични данни на обработващи лични данни за изпълнението на целите за обработване, с които сте се съгласили, при спазване на изискванията на Регламент (ЕС) 2016/679 (GDPR).</p>
                <p>(2) Администраторът Ви уведомява в случай на намерение да предаде част или всички Ваши лични данни на трети държави или международни организации.</p>
              </div>

              <div>
                <h2>Вашите права при събирането, обработването и съхранението на личните Ви данни</h2>
                <h3>Оттегляне на съгласието за обработване на личните Ви данни</h3>
                <p>Чл. 8. (1) Ако не желаете всички или част от Вашите лични данни да продължат да бъдат обработвани от Дружеството за конкретна или за всички цели на обработване, Вие можете по всяко време да оттеглите съгласието си за обработка чрез попълване на формата във Вашия профил или чрез искане в свободен текст.</p>
                <p>(2) Администраторът може да поиска да удостоверите своята самоличност и идентичност с лицето, за което се отнасят данните, като поиска от Вас да въведете имейл адрес и парола за достъп до сайта на място в офиса на Дружеството пред наш служител.</p>
                <p>(3) С оттегляне на съгласието за обработване на лични данни, които са задължителни за създаването и поддържане на профил в системата за отчет, Вашият акаунт ще стане неактивен. Разбира се, Вие ще можете да разглеждате сайта и предлаганите продукти и да извършвате поръчки като гост или да направите нова регистрация.</p>
                <p>(4) Ако има направена от Вас поръчка, която е в процес на обработване, най-ранният момент, в който можете да оттеглите съгласието си за обработване, е при успешното завършване на поръчката.</p>
              </div>

              <div>
                <h2>Право на достъп</h2>
                <p>Чл. 9. (1) Вие имате право да изискате и получите от Администратора потвърждение дали се обработват лични данни, свързани с Вас, като ако сте регистриран потребител, можете по всяко време да видите във Вашия профил личните данни, които сте предоставили и се обработват за Вас.</p>
                <p>(2) Вие имате право да получите достъп до данните, свързани с вас, както и до информацията, отнасяща се до събирането, обработването и съхранението на личните Ви данни.</p>
                <p>(3) Администраторът Ви предоставя при поискване, копие от обработваните лични данни, свързани с Вас, в електронна или друга подходяща форма.</p>
                <p>(4) Предоставянето на достъп до данните е безплатно, но Администраторът си запазва правото да наложи административна такса, в случай на повторяемост или прекомерност на исканията.</p>
              </div>

              <div>
                <h2>Право на коригиране или попълване</h2>
                <p>Чл. 10. Вие можете да коригирате или попълните неточните или непълните лични данни, свързани с Вас директно през Вашия профил в уебсайта или с отправяне на искане до Администратора.</p>
              </div>

              <div>
                <h2>Право на изтриване („да бъдеш забравен“)</h2>
                <p>Чл. 11. (1) Вие имате правото да поискате от Администратора изтриване на част или всички свързани с Вас лични данни, а Администраторът има задължението да ги изтрие без ненужно забавяне, когато е налице някое от посочените по-долу основания:</p>
                <ul>
                  <li>личните данни повече не са необходими за целите, за които са били събрани или обработвани по друг начин;</li>
                  <li>Вие оттеглите своето съгласие, върху което се основава обработването на данните и няма друго правно основание за обработването;</li>
                  <li>Вие възразите срещу обработването на свързаните с Вас лични данни, включително за целите на директния маркетинг и няма законни основания за обработването, които да имат преимущество;</li>
                  <li>личните данни са били обработвани незаконосъобразно;</li>
                  <li>личните данни трябва да бъдат изтрити с цел спазването на правно задължение по правото на ЕС или правото на държава членка, което се прилага спрямо Администратора;</li>
                  <li>личните данни са били събрани във връзка с предлагането на услуги на информационното общество.</li>
                </ul>
                <p>(2) Администраторът не е длъжен да изтрие личните данни, ако ги съхранява и обработва:</p>
                <ul>
                  <li>за упражняване на правото на свобода на изразяването и правото на информация;</li>
                  <li>за спазване на правно задължение, което изисква обработване, предвидено в правото на ЕС или правото на държавата членка, което се прилага спрямо Администратора или за изпълнението на задача от обществен интерес или при упражняването на официални правомощия, които са му предоставени;</li>
                  <li>по причини от обществен интерес в областта на общественото здраве;</li>
                  <li>за целите на архивирането в обществен интерес, за научни или исторически изследвания или за статистически цели;</li>
                  <li>за установяването, упражняването или защитата на правни претенции.</li>
                </ul>
                <p>(3) В случай на упражняване на правото Ви да бъдете забравени, Дружеството ще изтрие всички ваши данни, с изключение на следната информация:</p>
                <ul>
                  <li>информация, която е необходима, за да удостовери, че правото ви да бъдете забравени е изпълнено;</li>
                  <li>техническа информация за функционирането на системата за отчет, която информация не може да се свърже по никакъв начин с вашата личност;</li>
                  <li>електронна поща, с която сте извършили регистрация в системата за отчет.</li>
                </ul>
                <p>(4) За да упражните правото си за бъдете забравен, е необходимо да подадете заявка чрез профила си в онлайн магазина или с изпращане на искане по имейл до Администратора.</p>
                <p>(5) Администраторът може да поиска да удостоверите своята самоличност и идентичност с лицето, за което се отнасят данните.</p>
                <p>(6) Ако има направена от Вас поръчка, която е в процес на обработване, най-ранният момент, в който можете да поискате да бъдете „забравен“, е при успешното завършване на поръчката.</p>
                <p>(7) С изтриване на Вашите лични данни, Вашият акаунт ще стане неактивен. Разбира се, Вие ще можете да разглеждате сайта и предлаганите услуги  и да извършвате поръчки като гост или да направите нова регистрация.</p>
                <p>(8) Администраторът не изтрива данните, които има законово задължение да съхранява, включително за защита по повод отправени срещу него съдебни претенции или доказване на свои права.</p>
              </div>

              <div>
                <h2>Право на ограничаване</h2>
                <p>Чл. 12. Вие имате право да изискате от Администратора да ограничи обработването на свързаните с Вас данни, когато:</p>
                <ul>
                  <li>оспорите точността на личните данни, за срок, който позволява на Администратора да провери точността на личните данни;</li>
                  <li>обработването е неправомерно, но Вие не желаете личните данни да бъдат изтрити, а само използването им да бъде ограничено;</li>
                  <li>Администраторът не се нуждае повече от личните данни за целите на обработването, но Вие ги изисквате за установяването, упражняването или защитата на свои правни претенции;</li>
                  <li>Възразили сте срещу обработването в очакване на проверка дали законните основания на Администратора имат преимущество пред Вашите интереси.</li>
                </ul>
              </div>

              <div>
                <h2>Право на преносимост</h2>
                <p>Чл. 13. (1) Вие можете по всяко време да изтеглите или да получите в машинно-четим формат данните, които се съхраняват и обработват за Вас във връзка с използване на услугите на Администратора, директно през Вашия профил чрез опцията за експорт на данни или с искане по имейл.</p>
                <p>(2) Вие можете да поискате от Администратора директно да прехвърли Вашите лични данни към посочен от Вас администратор, когато това е технически осъществимо.</p>
              </div>

              <div>
                <h2>Право на получаване на информация</h2>
                <p>Чл. 14. Вие можете да поискате от Администратора да Ви информира относно всички получатели, на които личните данни, за които е поискано коригиране, изтриване или ограничаване на обработването, са били разкрити. Администраторът може да откаже да предостави тази информация, ако това би било невъзможно или изисква несъразмерно големи усилия.</p>
              </div>

              <div>
                <h2>Право на възражение</h2>
                <p>Чл. 15. Вие можете да възразите по всяко време срещу обработването на лични данни от Администратора, които се отнасят до него, включително ако се обработват за целите на профилиране или директен маркетинг.</p>
              </div>

              <div>
                <h2>Вашите права при нарушение на сигурността на личните ви данни</h2>
                <p>Чл. 16. (1) Ако Администраторът установи нарушение на сигурността на личните Ви данни, което може да породи висок риск за Вашите права и свободи, той Ви уведомява без ненужно забавяне за нарушението, както и за мерките, които са предприети или предстои да бъдат предприети.</p>
                <p>(2) Администраторът не е длъжен да Ви уведомява, ако:</p>
                <ul>
                  <li>е предприел подходящи технически и организационни мерки за защита по отношение на данните, засегнати от нарушението на сигурността;</li>
                  <li>е взел впоследствие мерки, които гарантират, че нарушението няма да доведе до висок риск за правата Ви;</li>
                  <li>уведомяването би изисквало непропорционални усилия.</li>
                </ul>
              </div>

              <div>
                <h2>Лица, на които се предоставят личните ви данни</h2>
                <p>Чл. 17. За целите на обработване на личните Ви данни и предоставяне на услугата в пълната й функционалност и с оглед Вашите интереси, Администраторът може да предостави данните на следните лица, които са обработващи данни: Спиди (Извършване на доставки за страната и чужбина), Еконт (Извършване на доставки за страната), Рапидо (Извършване на доставки за страната), ЛАРОК ИНТЕРНАЦИОНАЛ ООД (Счетоводни услуги). Посочените обработващи лични данни спазват всички изисквания за законност и сигурност при обработването и съхраняването на личните Ви данни.</p>
                <p>Чл. 18. Администраторът не извършва трансфер на вашите данни към трети държави.</p>
                <p>Чл. 19. В случай на нарушаване на правата Ви съгласно горепосоченото или приложимото законодателство за защита на личните данни, имате право да подадете жалба до Комисията за защита на личните данни, както следва:</p>
                Наименование: Комисия за защита на личните данни<br/>
                Седалище и адрес на управление: гр. София 1592, бул. „Проф. Цветан Лазаров” № 2<br/>
                Адрес за кореспонденция: гр. София 1592, бул. „Проф. Цветан Лазаров” № 2<br/>
                Телефон: 02 915 3 518<br/>
                Интернет страница: www.cpdp.bg
                <p>Чл. 20. Можете да упражните всичките си права относно защита на Вашите лични данни като отправите исканията си във всякаква форма, която съдържа изявление за това и ви идентифицира като притежател на данните.</p>
                <p>Чл. 21. Ако съгласието се отнася за трансфер, Администраторът описва възможните рискове за трансфера на данните към трети държави при липсата на решение за адекватна защита и подходящи средства за защита.</p>
              </div>
            </WrapChunks>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default PrivacyPolicy;