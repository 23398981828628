export const getUser = store => store.user;

export const getMusic = store => store.music;

export const getPosts = store => store.posts;

export const getRoutes = store => store.routes;

export const getTranslations = store => store.translations;

export const getServices = store => store.services;

export const getReferencesTexts = store => store.referencesTexts;

export const getClientReferences = store => store.clientReferences;

export const getClientLogos = store => store.clientLogos;