import React from 'react';
import { Helmet } from 'react-helmet';
import { Link as ScrollLink, Element } from 'react-scroll';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';

const sectionTitles = [
  'Мога ли да получа цена по телефона?',
  'Разпространението или печатът ще струва повече?',
  'Предлагате ли дизайн и печат на рекламните материали?',
  'Ползвате ли подизпълнители за разпространението на рекламните материали?',
  'Какво ще направите, ако служител на Ваш клиент Ви предложи да го „почерпите”?',
  'Възможно ли е да откажете определена кампания и защо?',
  'Вашата мисия?'
];

function WrapChunks({ children }) {
  return React.Children.map(children, (el, index) => {
    return (
      <Element id={`section-${index}`} name={`section-${index}`} className="reading-section">
        <a href={`#section-${index}`}>
          <FontAwesomeIcon icon={['fas', 'link']} />
        </a>
        {el}
      </Element>
    )
  })
}

function FaqAboutUs({ match }) {
  return (
    <Container className="page-p" id="faq-about-us">
      <Helmet>
        <title>БГ Плакат | Чести въпроси за нас</title>
        <meta name="description" content="От 2001 г. предлагаме разпространение на листовки, брошури и флаери. Посетете сайта, за да разгледате нашата система за отчет на разпространението в реално време." />
        <link rel="canonical" href={`https://www.bgplakat.com${match.path}`} />
      </Helmet>
      <Row>
        <Col lg={3} className="d-none d-lg-block">
          <div className="side-nav">
            {
              sectionTitles.map((title, index) => {
                return (
                  <Nav.Link key={index} as={ScrollLink} title={title} activeClass="active" to={`section-${index}`} offset={-10} spy={true} smooth={true} duration={250}>{title}</Nav.Link>
                )
              })
            }
          </div>
        </Col>
        <Col lg={9}>
          <div>
            <h1 className="text-primary text-center font-weight-bold mb-4">Чести въпроси за нас</h1>

            <p>Можете да видите още често задавани въпроси в разделите 
              „листовки“ и 
              <Link to="/plakati/chesti-vaprosi-plakati"> „плакати“</Link>.
            </p>
            <WrapChunks>
              <div>
                <h2>Мога ли да получа цена по телефона?</h2>
                <p>Да възможно е, но предпочитаме да изпращаме офертите си в писмен вид. Практиката е показала, че писмената комуникация (запитване-оферта) свежда до минимум възможността за недоразумение, което е в полза и за двете страни. По-обстойното разглеждане на конкретната поръчка обикновено довежда и до по-изгодна цена за клиента.</p>
              </div>

              <div>
                <h2>Разпространението или печатът ще струва повече?</h2>
                <p>Разпространението на рекламни материали, както всяка ръчна работа, би следвало да е по-скъпо от автоматизирания и механизиран процес на печатната машина. Изключение правят единствено големите брошури и каталози, за които, съвсем естествено, би имало по-високи разходи за печат.</p>
              </div>

              <div>
                <h2>Предлагате ли дизайн и печат на рекламните материали?</h2>
                <p>Логистиката и разпространението са основният фокус на дейността ни, но за улеснение на клиентите си предлагаме и целия спектър от услуги, свързани с кампанията им. Обикновено даваме насоки към най-подходящия според нас доставчик, но при желание от страна на клиента, поемаме целия ангажимент.</p>
              </div>

              <div>
                <h2>Ползвате ли подизпълнители за разпространението на рекламните материали?</h2>
                <p>През годините сме изградили стройна система от правила и принципи на работа, които са в основата на нашата идентичност. Държим да следим целия работен процес отблизо и да знаем от „първа ръка“ как протича разпространението във всеки един момент от кампанията. Ето защо, когато става въпрос за лепене на плакати и раздаване на листовки, изключително рядко се доверяваме на външни подизпълнители.</p>
              </div>

              <div>
                <h2>Какво ще направите, ако служител на Ваш клиент Ви предложи да го „почерпите”?</h2>
                <p>Ние вярваме, че колкото повече намaлява корупцията, толкова по-добър ще е бизнес климатът на България. Ето защо, дори при бегъл опит за въвличане в корупционна практика, наш принцип е да уведомим за това собственика на компанията. Искаме хората, които избират да работят с нас, да го правят поради това, което сме, а не поради това, че ще „почерпим“.</p>
              </div>

              <div>
                <h2>Възможно ли е да откажете определена кампания и защо?</h2>
                <p>Ясно осъзнаваме силното въздействие, което рекламата оказва върху хората. Случва се рядко, но е възможно да откажем кампания, ако визията или текста на рекламния материал, пряко или косвено са в разрез с християнския морал и норми на поведение. За повече информация вижте в <Link to="/za-nas/moralen-kodeks">моралния ни кодекс</Link>.</p>
              </div>

              <div>
                <h2>Вашата мисия?</h2>
                <p>Да спомагаме за подобряването на живота, както на отделния човек, така и на обществото като цяло. Повече за това кои сме, в какво вярваме и към какво се стремим, можете да видите в същностната ни идеология.</p>
              </div>
            </WrapChunks>
            <p>Можете да видите още често задавани въпроси в разделите 
              „листовки“ и 
              <Link to="/plakati/chesti-vaprosi-plakati"> „плакати“</Link>.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default FaqAboutUs;