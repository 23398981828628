import { FETCH_USER, FETCH_MUSIC, FETCH_POSTS } from "./actionTypes";

export const fetchUser = user => ({
  type: FETCH_USER,
  payload: user
});

export const fetchMusic = music => ({
  type: FETCH_MUSIC,
  payload: music
});

export const fetchPosts = posts => ({
  type: FETCH_POSTS,
  payload: posts
});
