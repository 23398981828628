import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from "react-redux";
import { getClientLogos } from "../redux/selectors";
import { Link } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';

function OurClients({ clientLogos }) {
  return (
    <Container className="page-p" id="nashite-klienti">
      <Helmet>
        <title>Нашите клиенти</title>
        <meta name="description" content="От 2001 г. предлагаме разнасяне на брошури, разпространение на флаери и лепене на плакати. Ꙭ Ела на сайта за повече информация." />
        <link rel="canonical" href="https://www.bgplakat.com/za-nas/nashite-klienti" />
      </Helmet>
      <Row className="justify-content-center">
        <Col lg={9}>
          <h1 className="mb-4">Нашите клиенти</h1>
          <Row className="justify-content-center" style={{background: 'white', borderRadius: '16px'}}>
            {
              clientLogos.map((logo, index) => {
                return (
                  <Col lg={3} md={4} sm={6} xs={6} key={index} className="text-center">
                    <Button href={logo.companyUrl} variant="link" rel="nofollow" target="_blank">
                      <Image className="client-logo" rounded src={logo.imageUrl} alt={logo.companyName} title={logo.companyName} />
                    </Button>
                  </Col>
                )
              })
            }
          </Row>
        </Col>
        <Col lg={9}>
          <Link to="/za-nas/referencii">Референции от клиенти</Link>
        </Col>
      </Row>
    </Container>
  );
}

const mapStateToProps = state => {
  const clientLogos = getClientLogos(state);
  return { clientLogos };
};

export default connect(
  mapStateToProps
)(OurClients);