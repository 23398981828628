import React from 'react';
import Select, { components } from 'react-select';

const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <span className="icon icon-arrow-down d-flex align-center align-items-center" />
      </components.DropdownIndicator>
    );
  };
  
const CustomSelect = (props) => {
  const { config, onChange, onBlur, name, value } = props;

  const handleChange = value => {
    onChange(name, value.value);
  };

  const handleBlur = () => {
    onBlur(name, true);
  };

  return (
    <Select
      className='react-select-container'
      classNamePrefix="react-select"
      components={{ DropdownIndicator }}
      options={config.options}
      placeholder={config.placeholder}
      theme={theme => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary25: '#F5CCAC',
          primary50: '#F5CCAC',
          primary: '#f4832e',
        },
      })}
      onChange={handleChange}
      onBlur={handleBlur}
      value={!!value && value.value}
    />
  )
}

export default CustomSelect;