import React from 'react';
import { matchPath } from "react-router";
import { connect } from "react-redux";
import { getRoutes } from "../redux/selectors";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

import bgplakat404 from '../assets/images/bgplakat-404.png';

function NoMatch(props) {
  const { match } = props;

  const paths = props.routes.map( route => route.path)
  paths.pop();

  const hasMatch = matchPath(match.url, {
    path: paths,
    exact: true,
    strict: false
  });

  return (
    <>
      { hasMatch ? null :
        <Container className="page-p" id="nomatch">
          <Helmet>
            <title>БГ Плакат | Грешка 404</title>
            <meta name="description" content="От 2001 г. предлагаме разпространение на листовки, брошури и флаери. Посетете сайта, за да разгледате нашата система за отчет на разпространението в реално време." />
            <link rel="canonical" href="https://bgplakat.com" />
          </Helmet>
          <Row>
            <Col md={7}>
              <h1 className="display-4 text-primary mb-lg-5">Хммм...</h1>
              <h2 className="display-4 nomatch-text mb-lg-5">Тази страница е преместена,<br/> никога не я е имало или просто<br/> отказва да се отвори.</h2>
              <h2 className="text-primary nomatch-advice">Не унивай,<br/>
                разгледай нашите услуги <Link className="nomatch-link" to="/uslugi">тук</Link><br/>
                или прочети нещо интересно в <Link className="nomatch-link" to="/blog">блога</Link>!</h2>
            </Col>
            <Col md={5}>
              <Image fluid src={bgplakat404} alt="Грешка 404, несъществуващ линк"/>
            </Col>
          </Row>
        </Container>
      }
    </>
  );
}

const mapStateToProps = state => {
  const routes = getRoutes(state);
  return { routes };
};

export default connect(
  mapStateToProps
)(NoMatch);