import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';

function LightboxWrapper({ images, photoIndex, onClose }) {
  const [ index, setIndex ] = useState(photoIndex);
  
  return (
    <Lightbox
      mainSrc={images[index]}
      nextSrc={images[(index + 1) % images.length]}
      prevSrc={images[(index + images.length - 1) % images.length]}
      onCloseRequest={onClose}
      onMovePrevRequest={() => setIndex((index + images.length - 1) % images.length)}
      onMoveNextRequest={() => setIndex((index + 1) % images.length)}
    />
  );
}

export default LightboxWrapper;