import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from "react-redux";
import { getUser } from "../redux/selectors";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import FormLogin from '../components/forms/FormLogin';

function Login({ user }) {
  return (
    <Container className="page-p" id="bgplakat-login">
      <Helmet>
        <title>БГ Плакат | Вход</title>
        <meta name="description" content="БГ Плакат разпространява листовки и брошури от 2001 г. насам. Имаме прозрачна система за следене. Разгледайте сайта за повече информация." />
        <link rel="canonical" href="https://www.bgplakat.com/vhod" />
      </Helmet>
      <Row className="justify-content-center">
        <Col lg={6}>
          { user ?
            <div className="subscribe-newsletter-long">
              <h1 className="text-primary font-weight-bold">{`Здравейте, ${user.name}!`}</h1>
            </div>
            :
            <div className="subscribe-newsletter-long">
              <h1 className="text-primary font-weight-bold">Вход БГ Плакат</h1>
              <hr className="mb-5" />
              <FormLogin />
              <Button variant="link" className="d-block text-secondary pl-0 py-0">Политика на поверителност »</Button>
            </div>
          }
        </Col>
      </Row>
    </Container>
  );
}

const mapStateToProps = state => {
  const user = getUser(state);
  return { user };
};

export default connect(
  mapStateToProps
)(Login);