import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import { Link } from "react-router-dom";

import Navigation from './components/Navigation';
import Routes from './components/Routes';
import CookieConsent from "react-cookie-consent";

import { library } from '@fortawesome/fontawesome-svg-core';
import { faFacebookF, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faUserCircle, faMusic, faLink } from '@fortawesome/free-solid-svg-icons';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-image-lightbox/style.css';
import './styles/icomoon.css';
import './styles/components.css';
import './styles/blogpostEditor.css';
import './styles/pages.css';
import './styles/transition.css';

library.add(faFacebookF, faInstagram, faLinkedinIn, faUserCircle, faMusic, faLink);

function App() {
  return (
    <div className="App">
      <Router>
        <Navigation />
        <Routes />
        <CookieConsent
          location="bottom"
          buttonText="Разбрах!"
          cookieName="bgplakatCookieConsent"
          style={{ background: "#2B373B" }}
          buttonStyle={{ background: "#f4832e", color: "white" }}
        >
          Тук има бисквитки! Използваме ги, за да предоставим полезно и безопасно съдържание на сайта, както и за да анализираме как се използва той.
          <Link className="ml-1" to="/politika-za-poveritelnost">Научи повече</Link>
        </CookieConsent>
      </Router>
    </div>
  );
}

export default App;
