import React, { useState } from 'react';
import { useFormik } from 'formik';
import { object, string, number } from 'yup';
import axios from 'axios';
import Checkbox from 'rc-checkbox';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import CustomSelect from '../CustomSelect';

const selectService = {
  placeholder: 'Продукт',
  options: [
    { value: 'Брошури по пощенските кутии', label: 'Брошури по пощенските кутии' },
    { value: 'Точково брошури', label: 'Точково брошури' },
    { value: 'Масово лепене на плакати', label: 'Масово лепене на плакати' },
    { value: 'Точково лепене на плакати', label: 'Точково лепене на плакати' },
    { value: 'Флаери, раздавани на ръка', label: 'Флаери, раздавани на ръка' },
    { value: 'Печат', label: 'Печат' },
    { value: 'Дизайн', label: 'Дизайн' },
    { value: 'Одит', label: 'Одит' },
    { value: 'Провеждане на анкети и проучвания', label: 'Провеждане на анкети и проучвания' },
    { value: 'Външна реклама', label: 'Външна реклама' },
    { value: 'Куриерска доставка', label: 'Куриерска доставка' }
]};

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const schema = object({
  name: string().required('Моля, въведете името си'),
  tel: string().matches(phoneRegExp, 'Моля, въведете валиден телефонен номер').required('Моля, въведете телефонен номер'),
  email: string().email('Моля, въведете валиден имейл адрес').required('Моля, въведете имейл адрес'),
  quantity: number().positive().integer()
});

function FormStartCampaign() {
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const [ showSuccess, setShowSuccess ] = useState(false);
  const [ showDanger, setShowDanger ] = useState(false);
  const [ showSuccessSubscribe, setShowSuccessSubscribe ] = useState(false);
  const [ showInfoInquiry, setShowInfoInquiry ] = useState(false);
  const [ showInfoSubscribe, setShowInfoSubscribe ] = useState(false);

  const formik = useFormik({
    validationSchema: schema,
    validateOnChange: false,
    validateOnBlur: true,
    initialValues: {
      name: '',
      tel: '',
      email: '',
      quantity: '100',
      service: '',
      place: '',
      area: '',
      subscribe: true,
      contactMe: false
    },
    onSubmit: (values, actions) => {
      const { name, tel, email, quantity, service, place, area, subscribe, contactMe } = values;
      const [ firstName, lastName ] = name.split(' ');

      let contactUrl = "https://clients.bgplakat.com/api/contact";
      if (process.env.NODE_ENV !== 'production') {
        contactUrl = "/contact";
      }

      let sendInBlueUrl = "https://clients.bgplakat.com/api/send-in-blue";
      if (process.env.NODE_ENV !== 'production') {
        sendInBlueUrl = "/send-in-blue";
      }

      setIsSubmitting(true);
      axios.request({
        method: 'post',
        url: contactUrl,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data: {
          title: 'Формуляр изпрати и получи оферта',
          sender: name,
          email: email,
          bodyMessage: `
            Желая да получа оферта за продукт [${service}] с тираж [${quantity}]бр.,
            в населено място [${place}], район [${area}].
            ${contactMe ? 'Не ми се попълва, направо се свържете с мен на посочените контакти' : ''}
            Контакти: 
            име: ${name}
            телефон за връзка: ${tel}
            имейл: ${email}
          `
        }
      })
      .then(res => {
        setIsSubmitting(false);
        setShowSuccess(true);
        setShowInfoInquiry(res.data.success);
      })
      .catch(err => {
        setIsSubmitting(false);
        setShowDanger(true);
        console.log(err)
      });

      if (subscribe) {
        axios.request({
          method: 'post',
          url: sendInBlueUrl,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          data: {
            firstName: firstName,
            lastName: lastName,
            email: email 
          }
        })
        .then(res => {
          setIsSubmitting(false);
          setShowSuccessSubscribe(true);
        })
        .catch(err => {
          setIsSubmitting(false);
          setShowInfoSubscribe(true);
          console.log(err.response)
        });
      }
    }
  });

  return (
    <Form className="form-start-campaign clearfix mb-4" noValidate onSubmit={formik.handleSubmit}>
      <Form.Group>
        <Form.Control
          as="input"
          type="text"
          id="nameStartCampaign"
          placeholder="Име*"
          name="name"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name}
          isInvalid={!!formik.touched.name && !!formik.errors.name}
        />
        {
          <Alert show={!!formik.touched.name && !!formik.errors.name} variant="danger">
            {formik.errors.name}
          </Alert>
        }
        <small className="form-text text-right text-muted font-italic pr-lg-5">*задължително поле</small>
      </Form.Group>
      <Form.Group>
        <Form.Control
          as="input"
          type="email"
          id="emailStartCampaign"
          placeholder="Имейл адрес*"
          name="email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          isInvalid={!!formik.touched.email && !!formik.errors.email}
        />
        {
          <Alert show={!!formik.touched.email && !!formik.errors.email} variant="danger">
            {formik.errors.email}
          </Alert>
        }
        <small className="form-text text-right text-muted font-italic pr-lg-5">*задължително поле</small>
      </Form.Group>
      <Form.Group>
        <Form.Control
          as="input"
          type="tel"
          id="telStartCampaign"
          placeholder="Телефон*"
          name="tel"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.tel}
          isInvalid={!!formik.touched.tel && !!formik.errors.tel}
        />
        {
          <Alert show={!!formik.touched.tel && !!formik.errors.tel} variant="danger">
            {formik.errors.tel}
          </Alert>
        }
        <small className="form-text text-right text-muted font-italic pr-lg-5">*задължително поле</small>
      </Form.Group>
      <Form.Row>
        <Form.Group className="mb-2rem" as={Col} lg={6}>
          <CustomSelect
            config={selectService}
            name="service"
            onChange={formik.setFieldValue}
            onBlur={formik.setFieldTouched}
            value={formik.values.service}
            error={formik.errors.service}
            touched={formik.touched.service}
          />
        </Form.Group>
        <Form.Group className="mb-2rem" as={Col} lg={6}>
          <Row>
            <Form.Label className="text-right pr-lg-0" column lg={5}>Тираж:</Form.Label>
            <Col lg={7}>
              <Form.Control
                as="input"
                type="text"
                id="inputQuantity"
                name="quantity"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.quantity}
                isInvalid={!!formik.errors.quantity}
              />
            </Col>
          </Row>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group className="mb-2rem" as={Col} lg={6}>
          <Form.Control
            as="input"
            type="text"
            id="placeStartCampaign"
            placeholder="Населено място"
            name="place"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.place}
            isInvalid={!!formik.touched.place && !!formik.errors.place}
          />
        </Form.Group>
        <Form.Group className="mb-2rem" as={Col} lg={6}>
          <Form.Control
            as="input"
            type="text"
            id="areaStartCampaign"
            placeholder="Допълнителна информация (квартал, район)"
            name="area"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.area}
            isInvalid={!!formik.touched.area && !!formik.errors.area}
          />
        </Form.Group>
      </Form.Row>
      <Form.Group as={Row}>
        <Col sm={{ span: 12 }}>
          <Button type="submit" variant="primary" className="float-right" disabled={isSubmitting}>Изпрати и получи оферта</Button>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-0">
        <Col sm={{ span: 12 }}>
          <label>
            <Checkbox
              onChange={e => formik.setFieldValue('contactMe', e.target.checked)}
            />
            &nbsp; Не ми се попълва, направо се свържете с мен на посочените контакти.
          </label>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-0">
        <Col sm={{ span: 12 }}>
          <label>
            <Checkbox
              defaultChecked
              onChange={e => formik.setFieldValue('subscribe', e.target.checked)}
            />
            &nbsp; Добре, може да ми пращате полезни за мен статии и промо оферти.
          </label>
        </Col>
      </Form.Group>

      <Alert show={showSuccess} variant="success" dismissible onClose={() => setShowSuccess(false)}>
        {showInfoInquiry}
        {/* Благодарим ви! Успешно изпратихте запитването. */}
      </Alert>
      <Alert show={showDanger} variant="danger" dismissible onClose={() => setShowDanger(false)}>
        Възникна грешка, моля опитайте пак.
      </Alert>

      <Alert show={showSuccessSubscribe} variant="success" dismissible onClose={() => setShowSuccessSubscribe(false)}>
        Aбонамента е успешен! Моля, потвърдете имейла в пощата си!
      </Alert>
      <Alert show={showInfoSubscribe} variant="info" dismissible onClose={() => setShowInfoSubscribe(false)}>
        Вече имате абонамент!
      </Alert>
    </Form>
  )
}

export default FormStartCampaign;