import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { fetchUser } from "../redux/actions";
import ls from 'local-storage';

import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';

import logo from '../assets/images/bgplakat-logo-redesign.png';

function Navigation({ fetchUser }) {
  useEffect(() => {
    const user = ls.get('bgplakatUser');
    if (user) {
      fetchUser(user);
    }
    let visited = ls.get('bgplakatVisited');
    if (visited) {
      ls.set('bgplakatVisited', visited + 1);
    } else {
      ls.set('bgplakatVisited', 1);
    }
  }, [fetchUser]);

  return (
    <header className="pt-lg-3 pb-lg-2">
      <Container>
        <Helmet>
          <script type="application/ld+json">
            {
              `{
                "@context": "https://schema.org",
                "@type": "Organization",
                "url": "https://www.bgplakat.com",
                "logo": "${logo}"
              }`
            }
          </script>
        </Helmet>
        <Navbar className="align-items-lg-end" collapseOnSelect={true} expand="lg" variant="light">
          <Navbar.Brand as={NavLink} to="/">
            <Image src={logo} fluid={true} alt="БГ Плакат | Разпространение на брошури и плакати"></Image>
          </Navbar.Brand>
          <div className="order-lg-3">
            <Navbar.Toggle className="border-0" aria-controls="main-navbar" />
            <Button className="phone" variant="" href="tel:+080090009">
              <span className="icon-phone text-primary mr-lg-2"></span>
              <h3 className="navbar-text text-primary text-nowrap font-weight-bold d-none d-lg-inline-block">0800 900 09</h3>
            </Button>
          </div>

          <Navbar.Collapse id="main-navbar">
            <Nav className="ml-auto">
              <Nav.Link eventKey={1} as={NavLink} active={false} exact to="/">НАЧАЛО</Nav.Link>
              <Nav.Link eventKey={2} as={NavLink} active={false} to="/za-nas" className="text-nowrap">ЗА НАС</Nav.Link>
              <Nav.Link eventKey={3} as={NavLink} active={false} to="/za-horata" className="text-nowrap">ЗА ХОРАТА</Nav.Link>
              <Nav.Link eventKey={4} as={NavLink} active={false} to="/otchet">ОТЧЕТ</Nav.Link>
              <Nav.Link eventKey={5} as={NavLink} active={false} to="/uslugi">УСЛУГИ</Nav.Link>
              <Nav.Link eventKey={6} as={NavLink} active={false} to="/blog">БЛОГ</Nav.Link>
              <Nav.Link eventKey={7} as={NavLink} active={false} to="/contacts">КОНТАКТИ</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </header>
  )
}

export default connect(
  null,
  { fetchUser }
)(Navigation);