import React, {useState, useEffect} from 'react';

function CtaButton({ children, wait }) {
  const [show, setShow] = useState('');

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow('show');
    }, wait);
    return () => {
      clearTimeout(timer)
    }
  }, [wait]);

  return (
    <div className={`fade ${show}`}>
      {children}
    </div>
  );
}

export default CtaButton;