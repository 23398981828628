import React from 'react';
import { Helmet } from 'react-helmet';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import FormContact from '../components/forms/FormContact';

function Contacts() {
  return (
    <Container className="page-p" id="contacts">
      <Helmet>
        <title>Контакти | Брошури Плакати Флаери | bgplakat.com</title>
        <meta name="description" content="От 2001 г. предлагаме разнасяне на брошури, разпространение на флаери и лепене на плакати. Ꙭ Ела на сайта за повече информация." />
        <link rel="canonical" href="https://www.bgplakat.com/contacts" />
      </Helmet>
      <Row>
        <Col lg={12}>
          <h1 className="display-4 text-primary mb-5">С какво можем да сме Ви полезни?</h1>
        </Col>
        <Col lg={8}>
          <FormContact />
        </Col>

        <Col lg={4}>
          <section className="contact-info bg-secondary">
            <article className="pl-lg-3">
              <div className="d-inline-block mr-4">
                <span className="icon icon-phone-ring text-primary"></span>
              </div>
              <div className="d-inline-block">
                <span className="contact-info-title text-secondary">Телефон:</span><br></br><span className="contact-info-content text-secondary">0800 900 09</span>
              </div>
            </article>
            <hr></hr>
            <article className="pl-lg-3">
              <div className="d-inline-block align-top mr-4">
                <span className="icon icon-envelope text-primary"></span>
              </div>
              <div className="d-inline-block">
                <span className="contact-info-title text-secondary">Имейл:</span><br></br><span className="contact-info-content text-secondary">
                    <a href="mailto:office@bgplakat.com" className="mail">office@bgplakat.com</a>
                  </span>
              </div>
            </article>
            <hr></hr>
            <article className="pl-lg-3">
              <div className="d-inline-block align-top mr-4">
                <span className="icon icon-clock-analog text-primary"></span>
              </div>
              <div className="d-inline-block">
                <span className="contact-info-title text-secondary">Работно време:</span><br></br><span className="contact-info-content text-secondary">9:00 - 18:00 ч<br></br>всеки работен ден</span>
              </div>
            </article>
            <hr></hr>
            <article className="pl-lg-3">
              <div className="d-inline-block align-top mr-4">
                <span className="icon icon-location text-primary"></span>
              </div>
              <div className="d-inline-block">
                <span className="contact-info-title text-secondary">Адрес:</span><br></br><span className="contact-info-content text-secondary">София 1505,<br></br>ул. "Митрополит<br></br>Панарет" 16</span>
              </div>
            </article>
          </section>
        </Col>
      </Row>
    </Container>
  );
}

export default Contacts;