import React, { useState, useEffect, useRef } from 'react';
import { connect } from "react-redux";
import { getMusic } from "../redux/selectors";
import { fetchMusic } from "../redux/actions";
import ReactPlayer from 'react-player';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ls from 'local-storage';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function ReactPlayerWrapper({ music, fetchMusic }) {
  const reactPlayerEl = useRef(null);
  const [ showModal, setShowModal ] = useState(false);
  const [ muted, setMuted ] = useState(true);
  const [ playing, setPlaying ] = useState(false);

  useEffect(() => {
    if (!ls.get('bgplakatVisited')) {
      if (music.isSet) {
        setPlaying(true);
        setMuted(music.isMuted);
      } else {
        window.addEventListener('scroll', handleScroll);
      }
    }
    return () => {
      if (!music.isSet) {
        window.removeEventListener('scroll', handleScroll);
      }
    }
  }, [music]);

  const handleScroll = (e) => {
    const rect = reactPlayerEl.current.wrapper.getBoundingClientRect();
    if (rect.top <= 230 && rect.top >= -230) {
      setShowModal(true);
    }
  }

  const handleClose = () => {
    setPlaying(true);
    setShowModal(false);
    fetchMusic({
      isMuted: true,
      isSet: true
    });
  }
  const handleStart = () => {
    setMuted(false);
    setPlaying(true);
    setShowModal(false);
    fetchMusic({
      isMuted: false,
      isSet: true
    });
  }

  return (
    <React.Fragment>
      <ReactPlayer
        ref={reactPlayerEl}
        controls
        url='https://www.dropbox.com/s/ro4gwhwit2st8p1/screens.mp4?dl=0'
        playing={playing}
        muted={muted}
        volume={0.25}
        loop={true}
        style={{ margin: "auto" }}
        width="100%"
        height="auto"
      />
      <Modal id="modal-start-music" className="modal-start-music" centered={true} scrollable={true} size="sm" show={showModal} onHide={handleClose} aria-labelledby="modal-start-music">
        <Modal.Header closeButton>
          <Modal.Title id="modal-start-music-title" className="text-primary">Със звук?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button variant="" onClick={handleStart}>
            <FontAwesomeIcon icon={['fas', 'music']} />
            <span className="ml-2">Да</span>
          </Button>
          <Button className="ml-2" variant="" onClick={handleClose}>
            <span>Не</span>
          </Button>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const music = getMusic(state);
  return { music };
};

export default connect(
  mapStateToProps,
  { fetchMusic }
)(ReactPlayerWrapper);