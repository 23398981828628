import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from "react-redux";
import { getClientReferences } from "../redux/selectors";
import LightboxWrapper from '../components/LightboxWrapper';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

function References({ clientReferences }) {
  const [ images ] = useState(clientReferences.map(ref => ref.photoUrl));
  const [ showLightbox, setShowLightbox ] = useState(false);
  const [ photoIndex, setPhotoIndex ] = useState(0);

  const openImage = (e) => {
    setPhotoIndex(e.currentTarget.getAttribute('data-photo-index'));
    setShowLightbox(true);
  }
  const closeImage = (e) => setShowLightbox(false)

  return (
    <Container className="page-p" id="referencii">
      <Helmet>
        <title>Референции</title>
        <meta name="description" content="От 2001 г. предлагаме разнасяне на брошури, разпространение на флаери и лепене на плакати. Ꙭ Ела на сайта за повече информация." />
        <link rel="canonical" href="https://www.bgplakat.com/za-nas/referencii" />
      </Helmet>
      <Row className="justify-content-center">
        <Col lg={9}>
          <h1 className="mb-4">Референции</h1>
        </Col>
        {
          clientReferences.map((ref, index) => {
            return (
              <Col lg={9} key={index}>
                <p className="format-paragraph font-italic mb-2">"{ref.text}"</p>
                <p className="font-italic text-right mb-2">{ref.owner}</p>
                <p className="text-right"><Button variant="link" onClick={openImage} data-photo-index={index}>цялата референция »</Button></p>
              </Col>
            )
          })
        }
      </Row>
      {
        showLightbox && <LightboxWrapper images={images} photoIndex={photoIndex} onClose={closeImage} /> 
      }
    </Container>
  );
}

const mapStateToProps = state => {
  const clientReferences = getClientReferences(state);
  return { clientReferences };
};

export default connect(
  mapStateToProps
)(References);