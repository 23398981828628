import React, { useState } from 'react';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import axios from 'axios';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

const schema = object({
  title: string().required('Моля, въведете заглавие'),
  name: string().required('Моля, въведете името си'),
  email: string().email('Моля, въведете валиден имейл адрес').required('Моля, въведете имейл адрес'),
  message: string().required('Моля, въведете съобщение'),
});

function FormContact() {
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const [ showSuccess, setShowSuccess ] = useState(false);
  const [ showDanger, setShowDanger ] = useState(false);
  
  const formik = useFormik({
    validationSchema: schema,
    validateOnChange: false,
    validateOnBlur: true,
    initialValues: {
      title: '',
      name: '',
      email: '',
      message: ''
    },
    onSubmit: (values, actions) => {
      const { title, name, email, message } = values;

      let url = "https://clients.bgplakat.com/api/contact";
      if (process.env.NODE_ENV !== 'production') {
        url = "/contact";
      }

      setIsSubmitting(true);
      axios.request({
        method: 'post',
        url: url,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data: {
          title: title,
          sender: name,
          email: email,
          bodyMessage: message 
        }
      })
      .then(res => {
        setIsSubmitting(false);
        setShowSuccess(true);
      })
      .catch(err => {
        setIsSubmitting(false);
        setShowDanger(true);
        console.log(err)
      });
    }
  });

  return (
    <Form className="form-contact clearfix mb-4" onSubmit={formik.handleSubmit}>
      <Form.Group>
        <Form.Control
          as="input"
          type="text"
          size="lg"
          id="inputTitle"
          placeholder="Заглавие"
          name="title"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.title}
          isInvalid={!!formik.touched.title && !!formik.errors.title}
        />
        {
          <Alert show={!!formik.touched.title && !!formik.errors.title} variant="danger">
            {formik.errors.title}
          </Alert>
        }
      </Form.Group>
      <Form.Row>
        <Form.Group as={Col} lg={6}>
          <Form.Control
            as="input"
            type="text"
            size="lg"
            id="inputName"
            placeholder="Вашето име"
            name="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            isInvalid={!!formik.touched.name && !!formik.errors.name}
          />
          {
            <Alert show={!!formik.touched.name && !!formik.errors.name} variant="danger">
              {formik.errors.name}
            </Alert>
          }
        </Form.Group>
        <Form.Group as={Col} lg={6}>
          <Form.Control
            as="input"
            type="email"
            size="lg"
            id="inputEmail"
            placeholder="Имейл"
            name="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            isInvalid={!!formik.touched.email && !!formik.errors.email}
          />
          {
            <Alert show={!!formik.touched.email && !!formik.errors.email} variant="danger">
              {formik.errors.email}
            </Alert>
          }
        </Form.Group>
      </Form.Row>
      <Form.Group>
        <Form.Control
          as="textarea"
          size="lg"
          id="textareaMessage"
          rows="8"
          placeholder="Съобщение"
          name="message"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.message}
          isInvalid={!!formik.touched.message && !!formik.errors.message}
        />
        {
          <Alert show={!!formik.touched.message && !!formik.errors.message} variant="danger">
            {formik.errors.message}
          </Alert>
        }
      </Form.Group>
      <Button type="submit" variant="primary" size="lg" className="float-right" disabled={isSubmitting}>ИЗПРАТИ</Button>

      <Alert show={showSuccess} variant="success" dismissible onClose={() => setShowSuccess(false)}>
        Благодарим ви! Ще получите отговор до 2 работни дни :)
      </Alert>
      <Alert show={showDanger} variant="danger" dismissible onClose={() => setShowDanger(false)}>
        Възникна грешка, опитайте отново по-късно.
      </Alert>
    </Form>
  )
}

export default FormContact;