import React from 'react';

import Modal from 'react-bootstrap/Modal';
import FormStartCampaign from './forms/FormStartCampaign';

function ModalStartCampaign({ show, handleClose }) {
  return (
    <Modal id="modal-start-campaign" centered={true} scrollable={true} size="lg" show={show} onHide={handleClose} aria-labelledby="modal-start-campaign-title">
      <Modal.Header closeButton>
        <Modal.Title id="modal-start-campaign-title" className="text-primary">ФОРМУЛЯР</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormStartCampaign />
      </Modal.Body>
    </Modal>
  );
}

export default ModalStartCampaign;