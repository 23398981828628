import React, { useState } from 'react';
import { connect } from "react-redux";
import { getReferencesTexts } from "../redux/selectors";
import { Helmet } from 'react-helmet';
import Truncate from 'react-truncate';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';

const zaHorata = "https://clients.bgplakat.com/images/1581952982.jpg";

function People({ referencesTexts }) {
  const [ name, setName ] = useState(false);
  const [ text, setText ] = useState(false);
  const [ show, setShow ] = useState(false);

  const handleShow = (e) => {
    setName(e.currentTarget.getAttribute('name'));
    setText(e.currentTarget.getAttribute('text'));
    setShow(true);
  }
  const handleClose = (e) => setShow(false);

  return (
    <Container className="page-p" id="people">
      <Helmet>
        <title>За хората | Брошури Листовки Плакати | bgplakat.com</title>
        <meta name="description" content="На пазара от 2001 г. БГ Плакат предлага разпространение на брошури и листовки и лепене на плакати. ✓ Ела на сайта за повече информация." />
        <link rel="canonical" href="https://www.bgplakat.com/za-horata" />
      </Helmet>
      <Row className="justify-content-center">
        <Col lg={9}>
          <article className="mb-5">
            <h1 className="display-4 text-primary mb-4">За нас ХОРАТА са на първо място</h1>
            <p className="format-paragraph">Хората са ни най-важни. Защото ни вдъхновяват и защото пренасят вашия бизнес на следващото ниво. И нашия бизнес също. Те не просто разпространяват брошури и лепят плакати. Те често са посланици на брандовете. На вашия бранд или на бранда на вашите клиенти. Хората са ни най-важни. Те си го знаят. Сега го знаете и вие.</p>
            <div className="people-image-container">
              <Image src={zaHorata} alt="за хората" />
            </div>
          </article>
          <article className="people-for-us mb-6">
            <h2>Вижте как служителите възприемат БГ Плакат:</h2>
            <hr/>
            {
              referencesTexts.map((reference) => {
                return (
                  <article className="people-reference mb-5" key={reference.id} onClick={handleShow} text={reference.text} name={reference.name}>
                    <h5 className="mb-1">{reference.name}:</h5>
                    <Truncate lines={2} ellipsis={<span>..."</span>}>
                      {reference.text}
                    </Truncate>
                  </article>
                )
              })
            }
          </article>
        </Col>
      </Row>
      <Modal id="modal-people-reference" centered={true} scrollable={true} size="lg" show={show} onHide={handleClose} aria-labelledby="modal-start-campaign-title">
        <Modal.Header closeButton>
          <Modal.Title id="modal-start-campaign-title" className="text-primary">{name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <quote className="format-paragraph font-italic">"{text}"</quote>
        </Modal.Body>
      </Modal>
    </Container>
  );
}

const mapStateToProps = state => {
  const referencesTexts = getReferencesTexts(state);
  return { referencesTexts };
};

export default connect(
  mapStateToProps
)(People);