import React from 'react';
import { Helmet } from 'react-helmet';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function MoralCode() {
  return (
    <Container className="page-p" id="moralen-kodeks">
      <Helmet>
        <title>Морален кодекс</title>
        <meta name="description" content="От 2001 г. предлагаме разнасяне на брошури, разпространение на флаери и лепене на плакати. Ꙭ Ела на сайта за повече информация." />
        <link rel="canonical" href="https://www.bgplakat.com/za-nas/moralen-kodeks" />
      </Helmet>
      <Row className="justify-content-center">
        <Col lg={9}>
          <h1>Морален кодекс</h1>

          <p>На всеки е известно, че рекламата оказва силно въздействие върху всички хора, независимо от възрастта им. Затова ние от БГ Плакат сме приели морален кодекс, с който искаме да поемем своята отговорност относно рекламите, в които сме ангажирани. Това означава, че не приемаме да работим с клиенти, чиято дейност или начин на представяне е в явно несъответствие с моралните ни принципи, изцяло съобразени с християнския мироглед.</p>
          <p>За повече яснота, предлагаме списък с дейности, услуги и продукти, които не рекламираме:</p>
          <ul>
            <li>Реклами, които пряко или косвено съдържат в себе си антихуманни послания, подтикващи към омраза, насилие, расизъм и всякакъв вид дискриминация.</li>
            <li>Продукти, вредящи на човешкото здраве – цигари, наркотици, твърд алкохол и други.</li>
            <li>Дейности, продукти и събития, свързани явно, или завоалирано, с човешката сексуалност – нощни клубове, стриптийз барове, секс магазини, порнографска литература, телевизионни предавания, паради, сайтове за запознанства и други.</li>
            <li>Свързани с хазарта дейности – казина, спортни залагания, тотализатори, лотарии и други.</li>
            <li>Всякакви други антихристиянски активности, мероприятия и литература.</li>
          </ul>
          <p>С цел да се избегнат недоразумения, бихме желали да съгласуваме визията на рекламните материали, преди да бъдат отпечатани.</p>
          <p>Нека заедно работим за напредъка на страната ни, а не за нейния морален упадък!</p>
        </Col>
      </Row>
    </Container>
  );
}

export default MoralCode;