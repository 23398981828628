import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { connect } from "react-redux";
import { fetchUser } from "../../redux/actions";
import ls from 'local-storage';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Checkbox from 'rc-checkbox';
import axios from 'axios';

const schema = object({
  email: string().email('Моля, въведете валиден имейл адрес').required('Моля, въведете имейл адрес'),
  password: string().required("Моля, въведете парола"),
});

function FormLogin({ fetchUser }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  let history = useHistory();

  const formik = useFormik({
    validationSchema: schema,
    validateOnChange: false,
    validateOnBlur: true,
    initialValues: {
      email: '',
      password: '',
      rememberMe: true,
    },
    onSubmit: (values, actions) => {
      const { email, password, rememberMe } = values;
      let url = "https://clients.bgplakat.com/api/login";

      if (process.env.NODE_ENV !== 'production') {
        url = "/login";
      }

      setIsSubmitting(true);
      axios.request({
        method: 'post',
        url: url,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data: {
          email: email,
          password: password,
        }
      })
      .then(res => {
        setIsSubmitting(false);
        fetchUser(res.data);
        ls.remove('bgplakatUser');
        if (rememberMe) {
          ls.set('bgplakatUser', res.data);
        }
        history.push("/blog");
      })
      .catch(err => {
        setIsSubmitting(false);
        console.log(err)
      });
    }
  });

  return (
    <Form className="form-subscribe-newsletter-long clearfix mb-4" noValidate onSubmit={formik.handleSubmit}>
      <Form.Group>
        <Form.Label className="text-dark font-weight-bold">Имейл адрес*</Form.Label>
        <Form.Control
          as="input"
          type="email"
          id="email"
          placeholder="въведи потребителско име или имейл адрес"
          name="email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          isInvalid={!!formik.touched.email && !!formik.errors.email}
        />
        {
          <Alert show={!!formik.touched.email && !!formik.errors.email} variant="danger">
            {formik.errors.email}
          </Alert>
        }
        <small className="form-text text-right text-muted font-italic pr-lg-5">*задължително поле</small>
      </Form.Group>

      <Form.Group>
        <Form.Label className="text-dark font-weight-bold">Парола*</Form.Label>
        <Form.Control
          as="input"
          type="password"
          id="password"
          placeholder="въведи парола"
          name="password"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
        />
        <small className="form-text text-right text-muted font-italic pr-lg-5">*задължително поле</small>
      </Form.Group>

      <Form.Group as={Row} className="mb-0">
        <Col sm={{ span: 12 }}>
          <label>
            <Checkbox
              defaultChecked
              onChange={e => formik.setFieldValue('rememberMe', e.target.checked)}
            />
            &nbsp; Запомни ме
          </label>
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Col sm={{ span: 12 }}>
          <Button type="submit" variant="primary" className="float-left my-4" disabled={isSubmitting}>Влез</Button>
        </Col>
      </Form.Group>
    </Form>
  )
}

export default connect(
  null,
  { fetchUser }
)(FormLogin);