import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { getServices, getClientReferences, getClientLogos } from "../redux/selectors";
import { Helmet } from 'react-helmet';
import Slider from "react-slick";
import _ from 'lodash';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';

import CtaButton from '../components/CtaButton';
import ModalStartCampaign from '../components/ModalStartCampaign';
import ReactPlayerWrapper from '../components/ReactPlayerWrapper';
import LightboxWrapper from '../components/LightboxWrapper';

function Home({ services, clientReferences, clientLogos }) {
  const [ showModalStart, setShowModalStart ] = useState(false);
  const [ images ] = useState(clientReferences.map( ref => ref.photoUrl ));
  const [ showLightbox, setShowLightbox ] = useState(false);
  const [ photoIndex, setPhotoIndex ] = useState(0);

  const openImage = (e) => {
    setPhotoIndex(e.currentTarget.getAttribute('data-photo-index'));
    setShowLightbox(true);
  }
  const closeImage = (e) => setShowLightbox(false)

  const handleShowModalStart = () => setShowModalStart(true);
  const handleCloseModalStart = () => setShowModalStart(false);

  return (
    <React.Fragment>
      <Container className="page-p pt-lg-4" id="home">
        <Helmet>
          <title>БГ Плакат | Раздаване на листовки и брошури | Логистика с хора</title>
          <meta name="description" content="От 2001 г. предлагаме разпространение на листовки, брошури и флаери. Посетете сайта, за да разгледате нашата система за отчет на разпространението в реално време." />
          <link rel="canonical" href="https://www.bgplakat.com" />
        </Helmet>
        <Row>
          <Col lg={7}>
            <section className="main-title mb-5 pb-lg-5">
              <h1 className="display-4 text-primary mb-5 pb-lg-5">Разпространение <br className="d-none d-lg-block"></br> на брошури и плакати</h1>
              <h2 className="display-4 text-primary mb-5">Умеем да работим с хора</h2>
              <CtaButton wait={1000}>
                <Button variant="primary" size="lg" onClick={handleShowModalStart}>Стартирай кампания</Button>
              </CtaButton>
            </section>
          </Col>
          <Col lg={5}>
            <aside className="clearfix">
              <div className="honeycomb-container main-menu">
                {
                  _.orderBy(services, 'place').map((service) => {
                    return (
                      <div className="honeycomb-item" key={`item-${service.id}`}>
                        <Link className="comb" to={{
                          pathname: `/uslugi/${service.pathName}`,
                          state: {serviceName: service.pathName}
                        }}>
                          <div className="comb-content">
                            <span className={`icon ${service.iconClassName}`}></span>
                            <span className="text">{service.name}</span>
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
            </aside>
          </Col>
          {/* <Col lg={12}>
            <section className="clearfix section-bottom mb-5">
              <h1 className="text-primary">В какво сме различни?</h1>
              <hr></hr>
              <h4 className="d-inline-block float-right pr-md-2 mt-2">
                <span>» Хората са нашата страст.</span>
                <span className="text-muted">Искаме да успяваме заедно и го правим.</span>
                <br></br>
                <span>» Прозрачни сме пред клиентите.</span>
                <span className="text-muted">Те знаят всичко във всеки момент.</span>
                <br></br>
                <span>» Оптимизираме всички процеси.</span>
                <span className="text-muted">Имаме софтуер без аналог на пазара.</span>
              </h4>
            </section>
          </Col> */}
          <Col lg={12}>
            <article className="which-are-bgplakat mb-5 mt-4">
              <h2 className="text-primary">Кои са БГ Плакат</h2>
              <hr/>
              <p className="format-paragraph mb-2">БГ Плакат сме в бизнеса от 2001 г. Раздавахме флаери и брошури из цяла София. Лепяхме плакати навсякъде. Бяха времена, когато нямаше много правила, но пък имаше много хъс и вдъхновение. Тогава, когато още никой нямаше Фейсбук. Ето защо в БГ Плакат знаем доста за това, как се върши тази работа. И най-вече, как работата се върши добре. 
                Знаем какво ще кажете, но нека ви опровергаем, не е старомодно. Вижте надолу.
              </p>
            </article>
          </Col>
          <Col lg={12}>
            <article className="benefits-bgplakat mb-5">
              <h2 className="text-primary">Защо ще имате полза от офлайн реклама с БГ Плакат</h2>
              <hr/>
              <p className="format-paragraph mb-1">» Това е още един канал за утвърждаване на вашия бранд или на бранда на вашите клиенти</p>
              <p className="format-paragraph mb-1">» Ще можете да се отличите в един свят, силно пренаситен от дигитални медии</p>
              <p className="format-paragraph mb-1">» Нашият начин на работа позволява да имате видимост върху целия процес по дистрибуция на флаери и брошури в реално време</p>
              <p className="format-paragraph mb-1">» Защото адблокърите не работят офлайн</p>
            </article>
          </Col>
          <Col lg={12}>
            <article className="video-bgplakat mb-5">
              <ReactPlayerWrapper />
            </article>
          </Col>
          <Col lg={12}>
            <article className="trust-bgplakat mb-5">
              <h2 className="text-primary">Защо да се доверите на БГ Плакат</h2>
              <hr/>
              <p className="format-paragraph mb-1">» Работата ни е съвсем прозрачна и вие ще можете да видите във всеки един момент докъде сме стигнали с раздаването на флаери</p>
              <p className="format-paragraph mb-1">» Предлагаме цялостна организация на разпространението на брошури и листовки: нещо, което ще спести време, пари и усилия на вашия бизнес</p>
              <p className="format-paragraph mb-1">» Десетки бизнеси като вашия вече постигнаха скок в продажбите с раздадените флаери и брошури от БГ Плакат</p>
              <p className="format-paragraph mb-1">» Коректни сме и с нас просто се работи добре</p>
            </article>
          </Col>
          <Col lg={12}>
            <section className="recommendations mb-5 pb-lg-5">
              <h3 className="text-primary">Препоръки от клиенти</h3>
              <hr/>
              <Slider
                autoplay={true}
                autoplaySpeed={7000}
                dots={true}
                infinite={true}
                speed={2000}
                slidesToShow={1}
                slidesToScroll={1}
                cssEase="ease"
              >
                {
                  clientReferences.map((ref, index) => {
                    return (
                      <article key={index}>
                        <p className="format-paragraph font-italic mb-2">{ref.text}</p>
                        <p className="font-italic text-right mb-2">{ref.owner}</p>
                        <p className="text-right"><Button variant="link" onClick={openImage} data-photo-index={index}>цялата референция »</Button></p>
                      </article>
                    )
                  })
                }
              </Slider>
            </section>
          </Col>
          <Col lg={12}>
            <article className="clients-bgplakat">
              <h3 className="text-primary">Клиенти</h3>
              <hr/>
              <Slider
                className="logo-slider"
                autoplay={true}
                autoplaySpeed={3000}
                infinite={true}
                speed={1000}
                slidesToShow={4}
                slidesToScroll={1}
                centerMode={true}
                cssEase="ease"
              >
                {
                  clientLogos.map((logo, index) => {
                    return (
                      <Button key={index} href={logo.companyUrl} variant="link" rel="nofollow" target="_blank">
                        <Image className="client-logo" rounded src={logo.imageUrl} alt={logo.companyName} title={logo.companyName} />
                      </Button>
                    )
                  })
                }
              </Slider>
            </article>
          </Col>
        </Row>
        {
          showLightbox && <LightboxWrapper images={images} photoIndex={photoIndex} onClose={closeImage} /> 
        }
        <ModalStartCampaign show={showModalStart} handleClose={handleCloseModalStart} />
      </Container>
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  const services = getServices(state);
  const clientReferences = getClientReferences(state);
  const clientLogos = getClientLogos(state);
  return { services, clientReferences, clientLogos };
};

export default connect(
  mapStateToProps
)(Home);