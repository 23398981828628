import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { getServices } from "../redux/selectors";
import { Helmet } from 'react-helmet';
import { Element, scroller } from 'react-scroll';
import _ from 'lodash';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import ModalService from '../components/ModalService';
import ModalStartCampaign from '../components/ModalStartCampaign';

function Services(props) {
  const { location } = props;
  useEffect(() => {
    let timer = null;
    if (location.state && location.state.serviceName) {
      timer = setTimeout(() => {
        const el = document.getElementById(`comb-${location.state.serviceName}`);
        if (el) {
          el.click();
          setId(parseInt(el.getAttribute('data-service-id'), 10));
          setShowModalService(true);
        }
      }, 500);
    }
    return () => {
      clearTimeout(timer)
    }
  }, [location]);

  const [id, setId] = useState('');
  const [showModalService, setShowModalService] = useState(false);
  const [showModalStart, setShowModalStart] = useState(false);

  const handleShowModalStart = () => {
    setShowModalService(false);
    setShowModalStart(true);
  }
  const handleCloseModalStart = () => setShowModalStart(false);

  const handleShow = (e) => {
    setId(parseInt(e.currentTarget.getAttribute('data-service-id'), 10));
    setShowModalService(true);
  }
  const handleCloseModalService = () => setShowModalService(false);

  const handleAutoScroll = (e) => {
    const id = e.currentTarget.getAttribute('data-article-id');
    scroller.scrollTo(id, {duration: 400, offset: -50, smooth: 'easeInOutQuart'});
  }

  return (
    <Container className="page-p mw-100" id="services">
      <Helmet>
        <title>Услуги | Брошури Листовки Флаери | bgplakat.com</title>
        <meta name="description" content="Разнасяне на брошури по пощенските кутии, лепене на плакати, раздаване на листовки и флаери. ✅ Посети сайта за повече информация." />
        <link rel="canonical" href="https://www.bgplakat.com/uslugi" />
      </Helmet>
      <Row className="justify-content-center justify-content-xl-end">
        <Col xl={6} lg={9}>
          <h1 className="display-4 text-primary mb-5">Офлайн реклама</h1>
          { 
            _.orderBy(props.services, 'place').map((service, index) => {
              return (
                <Element key={`service-${service.id}`} name={`service-${service.id}`}>
                  <article id={`service-${service.id}`} className="service d-md-flex text-center text-md-left mb-3">
                    <div className="hexagon service-hexagon mr-md-4 mb-3">
                      <svg role="img" aria-labelledby={`hexagoneTitle-${index} hexagoneDesc-${index}`} xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="-2 0 304 304" height="250px" xmlnsXlink="http://www.w3.org/1999/xlink">
                        <title id={`hexagoneTitle-${index}`}>{`Clickable hexagone ${service.name}`}</title>
                        <desc id={`hexagoneDesc-${index}`}>{`Clickable hexagone with ${service.name} service icon`}</desc>
                        <polygon onClick={handleShow} data-service-id={service.id} className="hex" points="300,150 225,280 75,280 0,150 75,20 225,20"></polygon>
                      </svg>
                      <span onClick={handleShow} data-service-id={service.id} className={"icon " + service.iconClassName}></span>
                    </div>
                    <div className="service-description">
                      <h4 className="text-primary" onClick={handleShow} data-service-id={service.id}>{service.name}</h4>
                      <hr/>
                      {
                        service.description.short.map((text, i) => {
                          return (
                            <p key={`text-${i}`} className="format-paragraph">{text}</p>
                          )
                        })
                      }
                      <div className="text-right">
                        <Button variant="link" onClick={handleShow} data-service-id={service.id}>Вижте повече »</Button>
                      </div>
                    </div>
                  </article>
                </Element>)
              })
          }
        </Col>
        <Col xl={3} className="d-none d-xl-block">
          <aside className="side-menu fixed-top sticky-top pt-3">
            <div className="honeycomb-container">
              {
                _.orderBy(props.services, 'place').map((service) => {
                  return (
                    <div className="honeycomb-item" key={`item-${service.id}`}><div className="comb" id={`comb-${service.pathName}`} onClick={handleAutoScroll} data-article-id={`service-${service.id}`} data-service-id={service.id}><div className="comb-content">
                      <span className={`icon ${service.iconClassName}`}></span><span className="text">{service.name}</span>
                    </div></div></div>
                  )
                })
              }
            </div>
          </aside>
        </Col>
      </Row>

      <ModalService show={showModalService} handleClose={handleCloseModalService} handleShowModalStart={handleShowModalStart} id={id} />
      <ModalStartCampaign show={showModalStart} handleClose={handleCloseModalStart} />
    </Container>
  );
}

const mapStateToProps = state => {
  const services = getServices(state);
  return { services };
};

export default connect(
  mapStateToProps
)(Services);