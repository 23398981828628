import React from 'react';
import { connect } from "react-redux";
import { getRoutes } from "../redux/selectors";
import { Switch, Route, withRouter } from "react-router-dom";
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import Breadcrumbs from './Breadcrumbs';
import Footer from './Footer';

import bgImage from '../assets/images/bg-honeycomb-2.png';

function Routes({ location, routes }) {
  return (
    <main role="main" style={{backgroundImage: `url(${bgImage})`}}>
      <TransitionGroup>
        <CSSTransition
          key={location.key}
          timeout={{ enter: 350, exit: 350 }}
          classNames="page"
        >
          <div className="page">
            <Switch location={location}>
              {
                routes.map(({exact, path, Component}) => {
                  return (
                    <Route key={path} exact={exact} path={path}>
                      { props => (
                          <>
                            <Breadcrumbs {...props} />
                            <Component {...props} />
                          </>
                        )
                      }
                    </Route>
                  )
                })
              }
            </Switch>
            <Footer />
          </div>
        </CSSTransition>
      </TransitionGroup>
    </main>
  )
}

const mapStateToProps = state => {
  const routes = getRoutes(state);
  return { routes };
};

export default connect(
  mapStateToProps
)(withRouter(Routes));