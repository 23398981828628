import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import moment from 'moment';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function BlogPost({ match }) {
  const [ post, setPost ] = useState();
  
  useEffect(() => {
    let url = "https://clients.bgplakat.com/api/blog_posts";
    if (process.env.NODE_ENV !== 'production') {
      url = "/blog_posts";
    }
  
    axios.request({
      method: 'get',
      url: url,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
    .then(res => {
      const blogpostpath = match.params.blogpostpath;
      const post = res.data.find(post => post.path === blogpostpath);
      setPost(post);
    })
    .catch(err => console.log(err));
  }, [match.params.blogpostpath]);

  return (
    <Container className="page-p" id="blog-post">
      { post ?
        <React.Fragment>
          <Helmet>
            <title>{`Блог | ${post.title} | bgplakat.com`}</title>
            <meta name="description" content={post.metaDescription} />
            <link rel="canonical" href="https://www.bgplakat.com/blog/" />
            <script key={post.id} type="application/ld+json">
              {
                `{
                  "@context": "https://schema.org",
                  "@type": "NewsArticle",
                  "headline": "${post.title}",
                  "datePublished": "${moment(post.created_at).format('MMMM Do YYYY, h:mm a')}",
                  "dateModified": "${moment(post.updated_at).format('MMMM Do YYYY, h:mm a')}",
                  "image": [
                    "${post.image}"
                  ]
                }`
              }
            </script>
          </Helmet>
          <Row className="justify-content-center" noGutters={true}>
            <Col lg={9}>
              <div className="blog-post" dangerouslySetInnerHTML={{ __html: post.html }} />
            </Col>
          </Row>
        </React.Fragment> : null
      }
    </Container>
  );
}

export default BlogPost;