import React, { useState } from 'react';
import { connect } from "react-redux";
import { getServices } from "../redux/selectors";
import Scrollbar from "react-scrollbars-custom";

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import hexagonBorderThick from '../assets/images/hexagon-border-thick.svg';

function ModalService({ id, show, handleClose, handleShowModalStart, services }) {
  const [name, setName] = useState('');
  const [iconClassName, setIconClassName] = useState('');
  const [fullDescription, setFullDescription] = useState([]);

  const handleEnter = () => {
    const { name, iconClassName, description } = services.find((service) => service.id === id);

    setName(name);
    setIconClassName(iconClassName);
    setFullDescription(description.full);
  }

  return (
    <Modal id="modal-service-more" centered scrollable="true" size="lg" show={show} onEnter={handleEnter} onHide={handleClose} aria-labelledby="modal-service-more-title">
      <Modal.Header closeButton>
        <div className="hexagon modal-hexagon d-none d-lg-block">
          <object type="image/svg+xml" alt="Hexagone shape with icon" data={hexagonBorderThick} height="180px" aria-label="Hexagone-modal"></object>
          <span className={`icon ${iconClassName}`}></span>
          <span className="hexagon-title text-primary">{name}</span>
        </div>
        <Modal.Title id="modal-service-more-title" className="text-primary d-lg-none">{name}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ height: "500px" }}>
        <Scrollbar style={{ width: "auto", height: 396 }}>
          <div className="text-center">
            <article>
              {
                fullDescription.map( (paragraph, i) => {
                  return (
                    <p key={i} className="format-paragraph">{paragraph}</p>
                  )
                })
              }
            </article>
            <Button variant="primary" onClick={handleShowModalStart}>Вземи оферта</Button>
          </div>
        </Scrollbar>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = state => {
  const services = getServices(state);
  return { services };
};

export default connect(
  mapStateToProps
)(ModalService);