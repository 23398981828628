import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { getUser } from "../redux/selectors";
import { fetchPosts } from "../redux/actions";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import axios from 'axios';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import UserStatusBar from '../components/UserStatusBar';
import BlogCards from '../components/BlogCards';

function Blog({ match, fetchPosts, user }) {
  useEffect(() => {
    let url = "https://clients.bgplakat.com/api/blog_posts";
    if (process.env.NODE_ENV !== 'production') {
      url = "/blog_posts";
    }
  
    axios.request({
      method: 'get',
      url: url,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
    .then(res => fetchPosts(res.data))
    .catch(err => console.log(err));
  }, [fetchPosts]);

  return (
    <Container className="page-p" id="blog">
      <Helmet>
        <title>Блог | bgplakat.com</title>
        <meta name="description" content="Виж на сайта полезни статии и материали за дизайн на брошури, листовки и плакати, както и информация за офлайн реклама. Запиши се за нашия нюзлетър." />
        <link rel="canonical" href="https://www.bgplakat.com/blog" />
      </Helmet>
      { user ?
          <Container>
            <Row>
              <Col lg={9} className="px-0">
                <Button as={Link} to="/blog/new" variant="primary" className="mb-3">Напиши статия</Button>
              </Col>
              <Col lg={3}>
                <UserStatusBar />
              </Col>
            </Row>
          </Container> : null
      }

      <BlogCards match={match} />
    </Container>
  );
}

const mapStateToProps = state => {
  const user = getUser(state);
  return { user };
};

export default connect(
  mapStateToProps,
  { fetchPosts }
)(Blog);