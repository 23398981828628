import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { getUser } from "../../redux/selectors";
import { useFormik } from 'formik';
import { object, string } from 'yup';
import axios from 'axios';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

const schema = object({
  path: string().required('Моля, въведете път'),
  title: string().required("Моля, въведете заглавие"),
});

function FormBlogPost(props) {
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const [ post, setPost ] = useState();
  let history = useHistory();

  useEffect(() => {
    setPost(props.post)
  }, [props.post])

  const formik = useFormik({
    validationSchema: schema,
    validateOnChange: false,
    validateOnBlur: true,
    enableReinitialize: true,
    initialValues: {
      path: post ? post.path : '',
      title: post ? post.title : '',
      metaDescription: post ? post.metaDescription : '',
      description: post ? post.description : '',
      image: post ? post.image : ''
    },
    onSubmit: (values, actions) => {
      const { path, title, metaDescription, description, image } = values;

      setIsSubmitting(true);
  
      const data = {
        path: path,
        title: title,
        metaDescription: metaDescription,
        description: description,
        html: createHtml(),
        image: image,
      };
      let url = "";
      let method = "";

      if (post) {
        // Update post
        method = 'put';
        url = `https://clients.bgplakat.com/api/blog_posts/${post.id}`;
        if (process.env.NODE_ENV !== 'production') {
          url = `/blog_posts/${post.id}`;
        }
      } else {
        // Create post
        method = 'post';
        url = "https://clients.bgplakat.com/api/blog_posts";
        if (process.env.NODE_ENV !== 'production') {
          url = "/blog_posts";
        }
      }

      if (props.user) {
        axios.request({
          method: method,
          url: url,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${props.user.access_token}`
          },
          data: data
        })
        .then(res => {
          setPost(res.data);
          setIsSubmitting(false);
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className='custom-ui'>
                  <h1>Запазена!</h1>
                  <p>{data.title}</p>
                </div>
              );
            }
          });
        })
        .catch(err => {
          setIsSubmitting(false);
        });
      }
    }
  });

  const createHtml = () => {
    const currentContent = props.editorState.getCurrentContent();
    const html = draftToHtml(convertToRaw(currentContent));
    return html;
  }

  const handleConfirmDelete = (e) => {
    confirmAlert({
      title: 'Внимание',
      message: 'Сигурни ли сте, че искате да премахнете статията?',
      buttons: [
        {
          label: 'Да, изтрий !',
          onClick: deletePost
        },
        {
          label: 'Не'
        }
      ]
    });
  }

  const deletePost = () => {
    let url = `https://clients.bgplakat.com/api/blog_posts/${post.id}`;
    if (process.env.NODE_ENV !== 'production') {
      url = `/blog_posts/${post.id}`;
    }

    axios.request({
      method: 'delete',
      url: url,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${props.user.access_token}`
      }
    })
    .then(res => {
      history.push("/blog");
    })
    .catch(err => console.log(err));
  }

  const handleFileChange = (e, setFieldValue) => {
    e.preventDefault();
    const file = e.target.files[0];

    let url = "https://clients.bgplakat.com/api/images";
    if (process.env.NODE_ENV !== 'production') {
      url = "/images";
    }
    const data = new FormData() 
    data.append('file', file)
    axios.post(url, data)
    .then(res => {
      formik.setFieldValue('image', res.data.url, false);
    })
    .catch(err => console.log(err));
  }

  return (
    <Form className="form-subscribe-newsletter-long clearfix mb-4" noValidate onSubmit={formik.handleSubmit}>
      <Form.Group>
        <Form.Label className="text-dark font-weight-bold">Заглавие*</Form.Label>
        <Form.Control
          as="input"
          type="text"
          id="title"
          placeholder="въведи заглавие"
          name="title"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.title}
          isInvalid={!!formik.touched.title && !!formik.errors.title}
        />
        {
          <Alert show={!!formik.touched.title && !!formik.errors.title} variant="danger">
            {formik.errors.title}
          </Alert>
        }
      </Form.Group>

      <Form.Group>
        <Form.Label className="text-dark font-weight-bold">URL път (пр. http://new.bgplakat.com/blog/vasheto-zaglavie-na-statiq)*</Form.Label>
        <Form.Control
          as="input"
          type="text"
          id="path"
          placeholder="vasheto-zaglavie-na-statiq"
          name="path"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.path}
          isInvalid={!!formik.touched.path && !!formik.errors.path}
        />
        {
          <Alert show={!!formik.touched.path && !!formik.errors.path} variant="danger">
            {formik.errors.path}
          </Alert>
        }
      </Form.Group>

      <Form.Group>
        <Form.Label className="text-dark font-weight-bold">Мета описание на статията</Form.Label>
        <Form.Control
          as="input"
          type="text"
          id="metaDescription"
          placeholder="въведи заглавие"
          name="metaDescription"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.metaDescription}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label className="text-dark font-weight-bold">Кратко описание на картичката на статията</Form.Label>
        <Form.Control
          as="input"
          type="text"
          id="description"
          placeholder="въведи заглавие"
          name="description"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.description}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label className="text-dark font-weight-bold">Снимка на картичката на статията</Form.Label>
        <Form.Control
          as="input"
          type="text"
          id="image"
          placeholder="URL"
          name="image"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.image}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label className="text-dark font-weight-bold">Качи файл</Form.Label>
        <Form.Control
          as="input"
          type="file"
          id="fileUpload"
          placeholder="Файл"
          name="filePath"
          onChange={handleFileChange}
        />
      </Form.Group>

      <Form.Group as={Row}>
        <Col sm={{ span: 12 }}>
          <Button type="submit" variant="primary" disabled={isSubmitting}>Запази</Button>
          { post ?
            <Button className="float-right" variant="danger" onClick={handleConfirmDelete}>Изтрий</Button>
            : null
          }
        </Col>
      </Form.Group>
    </Form>
  )
}

const mapStateToProps = state => {
  const user = getUser(state);
  return { user };
};

export default connect(
  mapStateToProps
)(FormBlogPost);