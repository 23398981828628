import React from 'react';
import { Helmet } from 'react-helmet';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import Button from 'react-bootstrap/Button';

// import FormSubscribeNewsLetterLong from '../components/forms/FormSubscribeNewsLetterLong';

function Subscription() {
  return (
    <Container className="page-p" id="about">
      <Helmet>
        <title>БГ Плакат | Абонамент</title>
        <meta name="description" content="БГ Плакат разпространява листовки и брошури от 2001 г. насам. Имаме прозрачна система за следене. Разгледайте сайта за повече информация." />
        <link rel="canonical" href="https://www.bgplakat.com/abonament" />
      </Helmet>
      <Row noGutters className="justify-content-center">
        <Col lg={6}>
          <div className="subscribe-newsletter-container">
            <iframe title="bgplakat subscribe to newsletter" width="100%" height="100%" src="https://a3d63b14.sibforms.com/serve/MUIEAPGCoyvZ1_XQ1RoG6qU_AsKNyEHIKM2enzKwzedjWLF7XCiKT3_Tc51rBRzzkoZhw7enjOKsgaWVgu5S6RF-IL_3P8vMWdN95qM6bPnvFbzU3PfyL4LBtDN8AUP6x-EXftfWqyTpxo6kaRCOxKHRL98h5xh3ETk_CQ3zYicYL8x-c-AXzheNTjWZGsomX-K0KSbH3khrzIPj" frameBorder="0" scrolling="no" allowFullScreen style={{display: "block", marginLeft: "auto", marginRight: "auto", maxWidth: "100%"}}></iframe>
          </div>
        </Col>

        {/* <Col lg={6}>
          <div className="subscribe-newsletter-long">
            <h1 className="text-primary font-weight-bold mb-4">Запиши се за нюзлетъра на БГ Плакат</h1>
            <p className="text-dark mb-4">Искаш ли да разбереш как да създадеш рекламни материали с нулев бюджет? Защо е добра идея да включиш офлайн реклама в другите си маркетинг канали? Как да оптимизираш рекламния си бюджет?</p>
            <p className="text-dark mb-4">Включи се в информационния бюлетин на БГ Плакат, за да получиш наготово ценни практики и съвети.</p>
            <hr className="mb-4" />
            <FormSubscribeNewsLetterLong />
            <Button variant="link" className="d-block text-secondary pl-0 py-0">Политика на поверителност »</Button>
          </div>
        </Col> */}
      </Row>
    </Container>
  );
}

export default Subscription;