import React, { useState, useEffect } from 'react';
// import { Component } from 'react';
import { connect } from "react-redux";
import { getUser } from "../redux/selectors";
import { Helmet } from 'react-helmet';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
// import { Modifier } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import axios from 'axios';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';

import FormBlogPost from './forms/FormBlogPost';

const toolbarOptions = {
  options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
  image: {
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    urlEnabled: true,
    uploadEnabled: true,
    alignmentEnabled: true,
    uploadCallback: uploadImageCallBack,
    previewImage: true,
    inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
    alt: { present: true, mandatory: true },
    defaultSize: {
      height: 'auto',
      width: 'auto',
    },
  }
};

// Postponed option Image Hyperlink
// ================================
// function CustomOption({ editorState, onChange }) {
//   const addImageLink = () => {
//     console.log(editorState.getCurrentContent());
//     console.log(editorState.getSelection());

//     const contentState = Modifier.replaceWithFragment(
//       editorState.getCurrentContent(),
//       editorState.getSelection()
//     );

//     onChange(EditorState.push(editorState, contentState, 'insert-fragment'));
//   };

//   return (
//     <div onClick={addImageLink}>ImageHyperLink</div>
//   );
// }

function uploadImageCallBack(file) {
  return new Promise(
    (resolve, reject) => {
      let url = "https://clients.bgplakat.com/api/images";
      if (process.env.NODE_ENV !== 'production') {
        url = "/images";
      }
      const xhr = new XMLHttpRequest();
      xhr.open('POST', url);
      // xhr.setRequestHeader('Authorization', `Bearer ${state.user.access_token}`);
      const data = new FormData();
      data.append('file', file);
      xhr.send(data);
      xhr.addEventListener('load', () => {
        const response = JSON.parse(xhr.responseText);
        resolve({ data: { link: response.url}});
      });
      xhr.addEventListener('error', () => {
        const error = JSON.parse(xhr.responseText);
        console.log(error);
        reject(error);
      });
    }
  );
}

function BlogPostEditor({ match, user }) {
  const [ editorState, setEditorState ] = useState(EditorState.createEmpty());
  const [ post, setPost ] = useState();

  useEffect(() => {
    if (match.path !== '/blog/new') {
      let url = "https://clients.bgplakat.com/api/blog_posts";
      if (process.env.NODE_ENV !== 'production') {
        url = "/blog_posts";
      }

      axios.request({
        method: 'get',
        url: url,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(res => {
        const blogpost = res.data.find(post => post.path === match.params.blogpostpath);
        if (blogpost.html) {
          const { contentBlocks, entityMap } = htmlToDraft(blogpost.html);
          const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
          const editorState = EditorState.createWithContent(contentState);
          setEditorState(editorState);
        }
        setPost(blogpost);
      })
      .catch(err => console.log(err));
    }
  }, [match.path, match.params.blogpostpath]);

  const onEditorStateChange = editorState => {
    setEditorState(editorState);
  };

  return (
    <Container className="page-p" id="blog-post-editor">
      <Helmet>
        <title>Блог редакция | bgplakat.com</title>
        <meta name="description" content="Виж на сайта полезни статии и материали за дизайн на брошури, листовки и плакати, както и информация за офлайн реклама. Запиши се за нашия нюзлетър." />
        <link rel="canonical" href="https://www.bgplakat.com/blog/new" />
      </Helmet>

      <Row className="justify-content-center" noGutters={true}>
        { user ?
          <React.Fragment>
            <Col lg={10}>
              <h4 className="mb-3">Съдържание на статията:</h4>
              <Editor
                editorState={editorState}
                wrapperClassName="blog-post-editor-wrapper"
                toolbarClassName="blog-post-editor-toolbar"
                editorClassName="blog-post-editor"
                toolbar={toolbarOptions}
                // toolbarCustomButtons={[<CustomOption />]}
                onEditorStateChange={onEditorStateChange}
              />
              <h4 className="mt-5">HTML резултат:</h4>
              <textarea
                className="blog-post-editor-html"
                disabled
                value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
              />
            </Col>
            <Col lg={10} className="mt-3">
              <h4 className="mt-4">Данни за картичката:</h4>
              <FormBlogPost editorState={editorState} post={post} />
            </Col>
          </React.Fragment>
          :
          <Col lg={10}>
            <Alert variant="info">
              Нямате достъп до тази страница.
            </Alert>
          </Col>
        }
      </Row>
    </Container> 
  );
}

const mapStateToProps = state => {
  const user = getUser(state);
  return { user };
};

export default connect(
  mapStateToProps
)(BlogPostEditor);