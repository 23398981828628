import React from 'react';
import { Helmet } from 'react-helmet';
import { Link as ScrollLink, Element } from 'react-scroll';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';

const sectionTitles = [
  'Имате ли капацитет за големи и експресни поръчки в цялата страна?',
  'Какъв е ефектът от лепенето на плакати в малките населени места?',
  'Знаeте ли с точност какъв брой плакати са необходими за всеки от градовете на България?',
  'Запознати ли сте с рекламните наредби и поемате ли отговорност пред местните власти?',
  'Какви гаранции давате, че всички плакати ще бъдат залепени според предварителните уговорки?',
  'С какво лепите плакатите?'
];

function WrapChunks({ children }) {
  return React.Children.map(children, (el, index) => {
    return (
      <Element id={`section-${index}`} name={`section-${index}`} className="reading-section">
        <a href={`#section-${index}`}>
          <FontAwesomeIcon icon={['fas', 'link']} />
        </a>
        {el}
      </Element>
    )
  })
}

function FaqPosters({ match }) {
  return (
    <Container className="page-p" id="faq-about-us">
      <Helmet>
        <title>БГ Плакат | Чести въпроси за плакати</title>
        <meta name="description" content="От 2001 г. предлагаме разпространение на листовки, брошури и флаери. Посетете сайта, за да разгледате нашата система за отчет на разпространението в реално време." />
        <link rel="canonical" href={`https://www.bgplakat.com${match.path}`} />
      </Helmet>
      <Row>
        <Col lg={3} className="d-none d-lg-block">
          <div className="side-nav">
            {
              sectionTitles.map((title, index) => {
                return (
                  <Nav.Link key={index} as={ScrollLink} title={title} activeClass="active" to={`section-${index}`} offset={-10} spy={true} smooth={true} duration={250}>{title}</Nav.Link>
                )
              })
            }
          </div>
        </Col>
        <Col lg={9}>
          <div>
            <h1 className="text-primary text-center font-weight-bold mb-4">Чести въпроси за плакати</h1>

            <p>Можете да видите още често задавани въпроси в разделите 
              „листовки“ и 
              <Link to="/za-nas/chesti-vaprosi-za-nas"> „за нас“</Link>.
            </p>
            <WrapChunks>
              <div>
                <h2>Имате ли капацитет за големи и експресни поръчки в цялата страна?</h2>
                <p>За последните осем години постепенно изградихме стабилна мрежа от коректни служители, обхващаща областните градове на страната. Благодарение на това успяваме да изпълняваме качествено и в срок редица национални кампании, включващи големи тиражи в много на брой населени места.</p>
              </div>

              <div>
                <h2>Какъв е ефектът от лепенето на плакати в малките населени места?</h2>
                <p>От опита си в над 300 населени места отчитаме, че присъствието на плакати в малките градове предизвиква силен обществен интерес и успява да популяризира рекламираната услуга/продукт в изключително кратък срок. Ако жителите в тези градове са част от Вашата целева група, ние определено препоръчваме този вид реклама.</p>
              </div>

              <div>
                <h2>Знаeте ли с точност какъв брой плакати са необходими за всеки от градовете на България?</h2>
                <p>Често се допуска грешката, тиражът за даден град да се определя единствено от неговата големина. Това е неправилно, тъй като точният брой плакати зависи и от други важни фактори – гъстота на рекламната инфраструктура, местната рекламна наредба и др. В резултат на многобройните ни национални кампании ние знаем всичко това и можем с точност да планираме колко плаката са необходими за покритието на даден град.</p>
              </div>

              <div>
                <h2>Запознати ли сте с рекламните наредби и поемате ли отговорност пред местните власти?</h2>
                <p>През последните години в много от градовете на страната се въведоха рекламни наредби, спазването на които се следи все по-стриктно. Ние не пренебрегваме този факт и постоянно се информираме за нововъведените промени. Нашите клиенти могат да бъдат спокойни, тъй като е наш ангажимент да комуникираме с местните власти, ако това се налага.</p>
              </div>

              <div>
                <h2>Какви гаранции давате, че всички плакати ще бъдат залепени според предварителните уговорки?</h2>
                <p>Добре извършената реклама с плакати е високо ефективна, но процесът на лепене обикновено е трудно проследим. За да дадем пълна гаранция на нашите клиенти, разработихме ефективна система за отчетност на всеки етап от поръчката. Ежедневно изпращаме график на лепене и предлагаме значителен брой снимки, които показват на практика къде и как са залепени над 90% от целия тираж.</p>
              </div>

              <div>
                <h2>С какво лепите плакатите?</h2>
                <p>Тъй като държим на качественото изпълнение на поръчките си, а също така служителите ни и тяхното здраве са приоритет за нас, лепим с екологично чисто и бързо разграждащо се тапетно лепило. В случаите, когато лепим плакати вътре в дадена сграда, използваме хартиено тиксо.</p>
              </div>
            </WrapChunks>
            <p>Можете да видите още често задавани въпроси в разделите 
              „листовки“ и 
              <Link to="/za-nas/chesti-vaprosi-za-nas"> „за нас“</Link>.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default FaqPosters;