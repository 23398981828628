import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import LightboxWrapper from '../components/LightboxWrapper';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';

const otchet1 = "https://clients.bgplakat.com/images/1581952454.png";
const otchet2 = "https://clients.bgplakat.com/images/1581952621.png";
const otchet3 = "https://clients.bgplakat.com/images/1581952633.png";
const otchet4 = "https://clients.bgplakat.com/images/1581952646.png";

const images = [
  otchet1,
  otchet2,
  otchet3,
  otchet4,
];

function Report() {
  const [ showLightbox, setShowLightbox ] = useState(false);
  const [ photoIndex, setPhotoIndex ] = useState(0);

  const openImage = (e) => {
    setPhotoIndex(e.currentTarget.getAttribute('data-photo-index'));
    setShowLightbox(true);
  }
  const closeImage = (e) => setShowLightbox(false)

  return (
    <Container className="page-p" id="report">
      <Helmet>
        <title>БГ Плакат | Брошури Листовки Плакати | Отчет</title>
        <meta name="description" content="Разполагаме с прозрачна система за отчет на разпространението на брошури, листовки и лепене на плакати. Виж повече на сайта." />
        <link rel="canonical" href="https://www.bgplakat.com/otchet" />
      </Helmet>
      <Row className="justify-content-center">
        <Col lg={9}>
          <article className="mb-6">
            <div className="row mb-3">
              <div className="col-lg-9 d-flex align-items-end">
                <h1 className="display-4 text-primary mb-3">Какво представлява?</h1>
              </div>
              <div className="col-lg-3">
                <a href="https://clients.bgplakat.com/login" className="report-login">
                  <div className="hexagon report-hexagon float-lg-right">
                    <svg role="img" aria-labelledby="hexagoneTitle hexagoneDesc" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="-2 0 304 304" height="100%" xmlnsXlink="http://www.w3.org/1999/xlink">
                      <title id="hexagoneTitle">Login</title>
                      <desc id="hexagoneDesc">Hexagone login</desc>
                      <polygon className="hex-content" points="300,150 225,280 75,280 0,150 75,20 225,20"></polygon>
                    </svg>
                    <span className="icon icon-door-exit"></span>
                    <span className="hexagon-title">ВХОД</span>
                  </div>
                </a>
              </div>
            </div>
            <p className="format-paragraph">За вашия бизнес и за бизнеса на вашите клиенти най-важно е доверието. (Важно е и извън бизнеса, разбира се! <span role="img" aria-label="winking face">😉</span>) Доверието, което клиентите имат към вас, доверието на вашите партньори. С годините сме разбрали колко трудно е да се печели доверие и колко съществена е репутацията. Ние си знаехме, че сме честни и на нас може да се разчита, но трябваше да измислим как да покажем това на вас.</p>
            <p className="format-paragraph">Затова разработихме система за отчет на предстоящата и свършената от нас работа. В тази система всеки може да види в реално време къде нашите хора раздават брошури, листовки и къде разпространяват флаери. Съвсем прозрачно се отчита къде се лепят плакати в момента и къде вече са залепени.</p>
            <p className="format-paragraph">Изградихме собствена национална мрежа, разделихме градовете на райони и микрорайони с наши очертания, синхронизирани с Google Maps, създадохме и актуализирахме немалка база данни с прецизна информация за броя на пощенските кутии по райони. През цялото време разсъждавахме от ваша гледна точка - коя информация ви е ценна, как може да сте сигурни, че брошурите реално отиват в пощенските кутии. </p>
            <p className="format-paragraph">И така, като си стиснем ръцете, всеки клиент получава достъп с профил до тази система, където може по всяко време да провери на какъв етап е процесът. Различните цветове отразяват прецизно напредъка на разпространението. Разгледайте <a href="https://www.dropbox.com/s/roug3oynx3okfci/screens.mp4?dl=0">тук</a> как точно работи системата за отчет.</p>
          </article>
        </Col>
        <Col lg={5}>
          <article className="mb-4">
            <h2>Карта на населено място</h2>
            <hr></hr>
            <p className="format-paragraph mb-0">Можеш да видиш в реално време разпределението по райони в населенoто място, където тече кампанията. И да проследиш докъде са стигнали колегите с разпространението на твоите печатни материали. Както се вижда картата е синхронизирана с Google Maps.</p>
          </article>
        </Col>
        <Col lg={4}>
          <Button variant="link" onClick={openImage} data-photo-index={0}>
            <Image className="mb-5" width="100%" fluid="true" alt="Report image" src={otchet1}></Image>
          </Button>
        </Col>
        <Col lg={5}>
          <article className="mb-4">
            <h2>Отчет на дейностите в графика</h2>
            <hr></hr>
            <p className="format-paragraph mb-0">Графиките показват реално колко брошури, листовки или плакати са били разпространени в рамките на твоята кампания. Ще можеш да разгледаш кой точно ги е раздал или разлепил и на коя дата. Няма скрито-покрито!</p>
          </article>
        </Col>
        <Col lg={4}>
          <Button variant="link" onClick={openImage} data-photo-index={1}>
            <Image className="mb-5" width="100%" fluid="true" alt="Report image" src={otchet2}></Image>
          </Button>
        </Col>
        <Col lg={5}>
          <article className="mb-4">
            <h2>Финансов отчет и цена на разнос</h2>
            <hr></hr>
            <p className="format-paragraph mb-0">Можеш да видиш точно колко харчи всеки елемент от кампанията и къде иммено отива твоя рекламен бюджет. Кой куриер колко работа е свършил. Изобщо, ще можеш детайлно да проследиш нашата работа и да планираш следващата си кампания на базата на реални данни.</p>
          </article>
        </Col>
        <Col lg={4}>
          <Button variant="link" onClick={openImage} data-photo-index={2}>
            <Image className="mb-5" width="100%" fluid="true" alt="Report image" src={otchet3}></Image>
          </Button>
        </Col>
        <Col lg={5}>
          <article className="mb-4">
            <h2>Приетата оферта</h2>
            <hr></hr>
            <p className="format-paragraph mb-0">Ако искаш да сравниш дали цените съответстват, можеш да си припомниш офертата, при която сме се съгласили да работим заедно. Можеш да провериш и точните райони, които сме обещали да покрием. Обещано, сторено!</p>
          </article>
        </Col>
        <Col lg={4}>
          <Button variant="link" onClick={openImage} data-photo-index={3}>
            <Image className="mb-5" width="100%" fluid="true" alt="Report image" src={otchet4}></Image>
          </Button>
        </Col>
      </Row>
      {
        showLightbox && <LightboxWrapper images={images} photoIndex={photoIndex} onClose={closeImage} /> 
      }
    </Container>
  );
}

export default Report;