import React from 'react';
import { connect } from "react-redux";
import { getPosts, getUser } from "../redux/selectors";
import { Link } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';

import Paginate from '../components/Paginate';

function BlogCards({ match, posts, user }) {
  return (
    <Paginate className="blog-pagination" setActivePage={parseInt(match.params.page, 10) || 1} elementsPerFirstPage={7} elementsPerPage={9} pageItems={4}>
      {
        posts && posts.map((post, index) => {
          if (index === 0) {
            return (
              <Col lg={12} key={index}>
                <Jumbotron fluid className="mb-4">
                  <Container>
                    <Row>
                      <Col lg={9} className="px-0">
                        <Image fluid src={post.image} alt="разпространение на брошури"></Image>
                      </Col>
                      <Col lg={3} className="pt-4 pt-lg-5 pb-3 d-flex flex-column justify-content-between align-items-stretch">
                        <h1 className="main-card-title text-primary">
                          <Card.Link as={Link} to={`/blog/${post.path}`}>{post.title}</Card.Link>
                        </h1>
                        <p className="main-card-text text-fade text-indent">{post.description}</p>
                        <div className="align-self-end text-right">
                          <Button className="py-0" as={Link} to={`/blog/${post.path}`} variant="link">Прочетете тук »</Button>
                          { user ? <Button  className="py-0" as={Link} to={`/blog/edit/${post.path}`} variant="link">Редактирай »</Button> : null }
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Jumbotron>
              </Col>
            )
          } else {
            return (
              <Col lg={4} key={index}>
                <Card className="mb-4">
                  <div className="card-img" title={post.title} style={{backgroundImage: `url(${post.image})`}} />
                  <Card.Body>
                    <Card.Title className="text-primary">
                      <Card.Link as={Link} to={`/blog/${post.path}`}>{post.title}</Card.Link>
                    </Card.Title>
                    <Card.Text className="text-fade text-indent">{post.description}</Card.Text>
                    <Button as={Link} to={`/blog/${post.path}`} variant="link" className="px-0 py-0 float-right">Прочетете тук »</Button>
                    { user ? <Button as={Link} to={`/blog/edit/${post.path}`} variant="link" className="px-0 py-0">Редактирай »</Button> : null }
                  </Card.Body>
                </Card>
              </Col>
            )
          }
        })
      }
    </Paginate>
  );
}

const mapStateToProps = state => {
  const posts = getPosts(state);
  const user = getUser(state);
  return { posts, user };
};

export default connect(mapStateToProps)(BlogCards);