import { FETCH_USER, FETCH_MUSIC, FETCH_POSTS } from "../actionTypes";

import Home from '../../pages/Home';
import About from '../../pages/About';
import People from '../../pages/People';
import Report from '../../pages/Report';
import Services from '../../pages/Services';
import Service from '../../pages/Service';
import Blog from '../../pages/Blog';
import BlogPost from '../../components/BlogPost';
import BlogPostEditor from '../../components/BlogPostEditor';
import Contacts from '../../pages/Contacts';
import Subscription from '../../pages/Subscription';
import Login from '../../pages/Login';
import PrivacyPolicy from '../../pages/PrivacyPolicy';
import NoMatch404 from '../../pages/NoMatch404';
import MoralCode from '../../pages/MoralCode';
import OurClients from '../../pages/OurClients';
import References from '../../pages/References';
import FaqAboutUs from '../../pages/FaqAboutUs';
import FaqPosters from '../../pages/FaqPosters';

const initialState = {
  user: null,
  music: {
    isMuted: false,
    isSet: false
  },
  posts: [],
  routes: [{
      exact: true,
      path: '/',
      Component: Home
    }, {
      exact: true,
      path: '/za-nas',
      Component: About
    }, {
      exact: true,
      path: '/za-nas/moralen-kodeks',
      Component: MoralCode
    }, {
      exact: true,
      path: '/za-nas/nashite-klienti',
      Component: OurClients
    }, {
      exact: true,
      path: '/za-nas/referencii',
      Component: References
    }, {
      exact: true,
      path: '/za-nas/chesti-vaprosi-za-nas',
      Component: FaqAboutUs
    }, {
      exact: true,
      path: '/plakati/chesti-vaprosi-plakati',
      Component: FaqPosters
    }, {
      exact: true,
      path: '/za-horata',
      Component: People
    }, {
      exact: true,
      path: '/otchet',
      Component: Report
    }, {
      exact: true,
      path: '/uslugi',
      Component: Services
    }, {
      exact: true,
      path: '/uslugi/broshuri-po-poshtenskite-kutii',
      Component: Services
    }, {
      exact: true,
      path: '/uslugi/masovo-lepene-na-plakati',
      Component: Services
    }, {
      exact: true,
      path: '/uslugi/tochkovo-lepene-na-plakati',
      Component: Services
    }, {
      exact: true,
      path: '/uslugi/tochkovo-broshuri',
      Component: Services
    }, {
      exact: true,
      path: '/uslugi/flaeri-razdavani-na-ryka',
      Component: Services
    }, {
      exact: true,
      path: '/uslugi/dizain',
      Component: Services
    }, {
      exact: true,
      path: '/uslugi/pechat',
      Component: Services
    }, {
      exact: true,
      path: '/uslugi/odit',
      Component: Services
    }, {
      exact: true,
      path: '/uslugi/provejdane-na-anketi-i-prouchvaniq',
      Component: Services
    }, {
      exact: true,
      path: '/uslugi/vynshna-reklama',
      Component: Services
    }, {
      exact: true,
      path: '/uslugi/kurierska-dostavka',
      Component: Services
    }, {
      exact: true,
      path: '/broshuri-po-poshtenskite-kutii',
      Component: Service
    }, {
      exact: true,
      path: '/tochkovo-broshuri',
      Component: Service
    }, {
      exact: true,
      path: '/masovo-lepene-na-plakati',
      Component: Service
    }, {
      exact: true,
      path: '/tochkovo-lepene-na-plakati',
      Component: Service
    }, {
      exact: true,
      path: '/flaeri-razdavani-na-ryka',
      Component: Service
    }, {
      exact: true,
      path: '/blog',
      Component: Blog
    }, {
      exact: true,
      path: '/blog/new',
      Component: BlogPostEditor
    }, {
      exact: false,
      path: '/blog/page/:page',
      Component: Blog
    }, {
      exact: false,
      path: '/blog/edit/:blogpostpath',
      Component: BlogPostEditor
    }, {
      exact: false,
      path: '/blog/:blogpostpath',
      Component: BlogPost
    }, {
      exact: true,
      path: '/contacts',
      Component: Contacts
    }, {
      exact: true,
      path: '/abonament',
      Component: Subscription
    }, {
      exact: true,
      path: '/vhod',
      Component: Login
    }, {
      exact: true,
      path: '/politika-poveritelnost',
      Component: PrivacyPolicy
    }, {
      exact: false,
      path: '*',
      Component: NoMatch404
    }
  ],
  translations: [
    {text: '/', translation: 'Начало'},
    {text: 'za-nas', translation: 'За нас'},
    {text: 'moralen-kodeks', translation: 'Морален кодекс'},
    {text: 'nashite-klienti', translation: 'Нашите клиенти'},
    {text: 'referencii', translation: 'Референции'},
    {text: 'chesti-vaprosi-za-nas', translation: 'Чести въпроси за нас'},
    {text: 'chesti-vaprosi-plakati', translation: 'Чести въпроси за плакати'},
    {text: 'politika-poveritelnost', translation: 'Политика на поверителност'},
    {text: 'plakati', translation: 'Плакати'},
    {text: 'za-horata', translation: 'За хората'},
    {text: 'otchet', translation: 'Отчет'},
    {text: 'uslugi', translation: 'Услуги'},
    {text: 'blog', translation: 'Блог'},
    {text: 'contacts', translation: 'Контакти'},
    {text: 'vynshna-reklama', translation: 'Външна реклама'},
    {text: 'provejdane-na-anketi-i-prouchvaniq', translation: 'Провеждане на анкети и проучвания'},
    {text: 'odit', translation: 'Одит'},
    {text: 'pechat', translation: 'Печат'},
    {text: 'dizain', translation: 'Дизайн'},
    {text: 'kurierska-dostavka', translation: 'Куриерска доставка'},
    {text: 'tochkovo-lepene-na-plakati', translation: 'Точково лепене на плакати'},
    {text: 'masovo-lepene-na-plakati', translation: 'Масово лепене на плакати'},
    {text: 'tochkovo-broshuri', translation: 'Точково брошури'},
    {text: 'flaeri-razdavani-na-ryka', translation: 'Флаери раздавани на ръка'},
    {text: 'broshuri-po-poshtenskite-kutii', translation: 'Брошури по пощенските кутии'},
    {text: 'dizain-na-broshura-chast1', translation: 'Дизайн на брошура - Част I'},
    {text: 'dizain-na-broshura-chast2', translation: 'Дизайн на брошура - Част II'},
    {text: 'dizain-na-broshura-chast3', translation: 'Дизайн на брошура - Част III'}
  ],
  services: [{
    id: 1,
    place: 10,
    pathName: 'vynshna-reklama',
    name: 'Външна реклама',
    image: '',
    iconClassName: 'icon-billboard',
    description: {
      short: ['Вижте само колко дебела сянка хвърлят големите брандове на всеки оживен булевард в София и страната. Билбордът е добре известен канал за разпознаваемост на бранда. Тук, както в изкуството, все по-съществено става посланието, което те кара да се замислиш, да се усмихнеш, да почувстваш нещо. Нима не ви се иска и вашият бранд да го може?'],
      full: ['Вижте само колко дебела сянка хвърлят големите брандове на всеки оживен булевард в София и страната. Билбордът е добре известен канал за разпознаваемост на бранда. Тук, както в изкуството, все по-съществено става посланието, което те кара да се замислиш, да се усмихнеш, да почувстваш нещо. Нима не ви се иска и вашият бранд да го може? Ако мислите мащабно, и рекламата ви трябва да е мащабна. Направо широкомащабна. И да напомня за вас при всеки сгоден случай. Ние можем да ви помогнем за това!']
    }
  }, {
    id: 2,
    place: 9,
    pathName: 'provejdane-na-anketi-i-prouchvaniq',
    name: 'Провеждане на анкети и проучвания',
    image: '',
    iconClassName: 'icon-chart',
    description: {
      short: ['Ето от тук тръгва всичко. Качественото и професионално извършено маркетингово проучване е и кокошката, и яйцето. Няма да ви казваме колко пъти сме били свидетели на това, как един бизнес се върти в кръг и управленският екип допуска едни и същи грешки. Да, както и вие си помислихте, защото няма точните данни.'],
      full: ['Ето от тук тръгва всичко. Качественото и професионално извършено маркетингово проучване е и кокошката, и яйцето. Няма да ви казваме колко пъти сме били свидетели на това, как един бизнес се върти в кръг и управленският екип допуска едни и същи грешки. Да, както и вие си помислихте, защото няма точните данни. Няма изводите, които да служат за ветропоказател. Без ключова анкета няма как да сте сигурни кои са най-свирепите ви конкуренти, какъв точно е пазарният ви дял и какви постижими и измерими цели да заложите. (Колегите от маркетинга знаят колко е важно, казваме го за другите.) Имаме хора и за това! Ще направим точното маркетингово проучване, така, както е зададено от управителя и маркетинговия мениджър на вашия бизнес.']
    }
  }, {
    id: 3,
    place: 8,
    pathName: 'odit',
    name: 'Одит',
    image: '',
    iconClassName: 'icon-sheet-checkmark',
    description: {
      short: ['С тази услуга ще ви изненадаме, ще изненадаме и вашите клиенти или служители. Предлагаме проверка, която била висша форма на доверие. Можем да направим одит на качеството и проследяване: как се извършва предлаганата от вас услуга, как се раздават флаери и брошури, дали вашите служители си вършат работата.'],
      full: ['С тази услуга ще ви изненадаме, ще изненадаме и вашите клиенти или служители. Предлагаме проверка, която била висша форма на доверие. Можем да направим одит на качеството и проследяване: как се извършва предлаганата от вас услуга, как се раздават флаери и брошури, дали вашите служители си вършат работата. Ще пратим “таен клиент”, ще се промъкнем до служителите ви под прикритие, ще проверим открито онова, което ви е любопитно или ви тревожи. Имаме точните хора в цялата страна! Те искат вашия бизнес да успее.']
    }
  }, {
    id: 4,
    place: 7,
    pathName: 'pechat',
    name: 'Печат',
    image: '',
    iconClassName: 'icon-sheets',
    description: {
      short: ['През годините отсяхме и съхранихме най-ценните контакти с печатници из цялата страна. Както вече ви доверихме, хората са ни най-важни. Затова тези ценни взаимоотношения с партньорите ни всъщност са безценни. Ще получим за вас най-добрите условия...'],
      full: ['През годините отсяхме и съхранихме най-ценните контакти с печатници из цялата страна. Както вече ви доверихме, хората са ни най-важни. Затова тези ценни взаимоотношения с партньорите ни всъщност са безценни. Ще получим за вас най-добрите условия за отпечатването на вашите рекламни материали, ще ви свържем точно с когото трябва. Направо ще свършим работата вместо вас!']
    }
  }, {
    id: 5,
    place: 6,
    pathName: 'dizain',
    name: 'Дизайн',
    image: '',
    iconClassName: 'icon-shape',
    description: {
      short: ['Нашите графични дизайнери наистина умеят да синтезират посланието зад бранда. Те силно не понасят кича и клишето. Разбират вашата идея и я надграждат така, че тя вече изглежда пораснала и завършена, а не рошава и неориентирана. Предлагаме оригинален графичен и уеб дизайн, които ще изградят цялостна бранд идентичност на вашия бизнес...'],
      full: ['Нашите графични дизайнери наистина умеят да синтезират посланието зад бранда. Те силно не понасят кича и клишето. Разбират вашата идея и я надграждат така, че тя вече изглежда пораснала и завършена, а не рошава и неориентирана. Предлагаме оригинален графичен и уеб дизайн, които ще изградят цялостна бранд идентичност на вашия бизнес - от логото до пластиките по прозорците. Най-голяма стойност има това, че можете да получите пълен цикъл от услуги в БГ Плакат. От дизайн, през предпечат и печат, до разнасяне и пълноценен отчет.']
    }
  }, {
    id: 6,
    place: 11,
    pathName: 'kurierska-dostavka',
    name: 'Куриерска доставка',
    image: '',
    iconClassName: 'icon-envolope-hand',
    description: {
      short: ['Планирали сте разнасянето на брошурите, дизайнът отдавна е одобрен, всичко е отпечатано и пакетирано. Нашите “пчелички” са в нисък старт по градове и паланки. Но изведнъж, о ужас, осъзнавате, че сте пропуснали логистиката по транспортирането на тези материали.'],
      full: ['Планирали сте разнасянето на брошурите, дизайнът отдавна е одобрен, всичко е отпечатано и пакетирано. Нашите “пчелички” са в нисък старт по градове и паланки. Но изведнъж, о ужас, осъзнавате, че сте пропуснали логистиката по транспортирането на тези материали. Оказва се, че трябва някак два-три стелажа и малко кашонче с плакати да се озоват във всеки от магазините в мрежата. Вместо да търсите нов доставчик, този път на куриерски услуги, да се справяте с липсата на време, може да възложите и тази задача на БГ Плакат. Не искаме да сме нескромни, но ние можем всичко. Просто с годините научихме как да сме ви от полза съвсем, съвсем максимално.']
    }
  }, {
    id: 7,
    place: 3,
    pathName: 'tochkovo-lepene-na-plakati',
    name: 'Точково лепене на плакати',
    image: "https://clients.bgplakat.com/images/1581952169.jpg",
    iconClassName: 'icon-poster',
    description: {
      short: ['Тук вече си дойдохме на думата. Точковото лепене на плакати се прави в конкретни сгради и около тях, например училища, университети, бизнес центрове и много други. Направо сме врели и кипели в това, правим го от много години и сме натрупали доста контакти. Имаме солидна база данни не само в София, но и в цялата страна.'],
      full: ['Тук вече си дойдохме на думата. Точковото лепене на плакати се прави в конкретни сгради и около тях, например училища, университети, бизнес центрове и много други. Направо сме врели и кипели в това, правим го от много години и сме натрупали доста контакти. Имаме солидна база данни не само в София, но и в цялата страна.', 'Досещате се, че като се организира подобна дейност, е нужно разрешението на хората, които отговарят за сградата. Но нали помните, хората са ни силната страна, затова ние водим преговори и открит диалог, за да свършим работата добре.', 'Точковото лепене на плакати ви дава гарантиран контакт с клиентите, които са нужни на вашия бизнес. Особено днес, когато местата за масово лепене стават все по-малко. Освен това процесът е абсолютно прозрачен. През цялата кампания ще имате списък по дати с достигнатите адреси и подробен снимков материал.']
    }
  }, {
    id: 8,
    place: 2,
    pathName: 'masovo-lepene-na-plakati',
    name: 'Масово лепене на плакати',
    image: "https://clients.bgplakat.com/images/1581951804.jpg",
    iconClassName: 'icon-posters',
    description: {
      short: ['Представлява лепене на плакати из целия град. Плакатите се лепят с лепило върху афишни колонки, плакатни табла, строителни огради и др. Това най-често са места, където минават най-много хора. Както е видно от името на услугата, целта е рекламата да достигне до масовия потребител и до най-многобройна аудитория.'],
      full: ['Представлява лепене на плакати из целия град. Плакатите се лепят с лепило върху афишни колонки, плакатни табла, строителни огради и др. Това най-често са места, където минават най-много хора. Както е видно от името на услугата, целта е рекламата да достигне до масовия потребител и до най-многобройна аудитория.', 'София си е София с всичкия мащаб на една столица. Но и в по-малките населени места този вид офлайн реклама е изключително успешен, поради по-малкото насищане с визуална информация. За кратко време вашият продукт или услуга предизвиква силен обществен интерес и става популярна. Стартира се и напълно безплатния маркетинг “по препоръка” или “от уста на уста”.', 'Много, много важно е разпространението на плакатите да се извършва коректно, за да е ефективна рекламата на вашия бизнес. Затова вие ще получавате снимково доказателство буквално за всеки разлепен плакат, така че да сте сигурни в нашите хора, колкото сме сигурни и ние. А ние сме напълно сигурни!']
    }
  }, {
    id: 9,
    place: 4,
    pathName: 'tochkovo-broshuri',
    name: 'Точково брошури',
    image: "https://clients.bgplakat.com/images/1581952146.jpg",
    iconClassName: 'icon-location-map',
    description: {
      short: ['За точковото раздаване на брошури имаме огромна база данни. А това е важно за вашия бизнес, защото нашата мрежа е разработвана от години и покрива цялата страна. Но най-вече е важно за вашия бизнес това, че нашата система за отчет на разпространението ви позволява да виждате буквално в реално време как точно си вършим работата. Ние знаем, че я вършим добре, но искаме да го покажем и на вас.'],
      full: ['За точковото раздаване на брошури имаме огромна база данни. А това е важно за вашия бизнес, защото нашата мрежа е разработвана от години и покрива цялата страна. Но най-вече е важно за вашия бизнес това, че нашата система за отчет на разпространението ви позволява да виждате буквално в реално време как точно си вършим работата. Ние знаем, че я вършим добре, но искаме да го покажем и на вас.', 'Самото разпространение на брошури се прави на конкретни места, които имат целеви трафик от хора. Например пощенски кутии, рецепции, стелажи. Често пъти се налага да преговаряме със собственици на сгради и домоуправители, но това не е проблем за нас. Вашата аудитория е и наша аудитория. Ще разпространим вашите брошури на всяка цена. Ще го направим добре и в срок! Пред погледа ви.Разгледайте системата за отчет тук. [видео]']
    }
  }, {
    id: 10,
    place: 5,
    pathName: 'flaeri-razdavani-na-ryka',
    name: 'Флаери, раздавани на ръка',
    image: "https://clients.bgplakat.com/images/1581952122.jpg",
    iconClassName: 'icon-brochure-hand',
    description: {
      short: ['За нашата база данни вече се похвалихме! Сега да ви разкажем за хората - нашите хора са важни за вас също, понеже при раздаването на флаери те общуват директно с вашите клиенти. Ние знаем това добре и затова обучаваме специално тези хора, нали те представят вашия бранд. Освен това имаме безценна информация за най-важните локации и за очаквания трафик от хора.'],
      full: ['За нашата база данни вече се похвалихме! Сега да ви разкажем за хората - нашите хора са важни за вас също, понеже при раздаването на флаери те общуват директно с вашите клиенти. Ние знаем това добре и затова обучаваме специално тези хора, нали те представят вашия бранд. Освен това имаме безценна информация за най-важните локации и за очаквания трафик от хора. И да, разбира се, че ще я споделим; ще ви помогнем да определите и оптималния тираж. Благодарение на специалната ни система за отчет ще можете да виждате всички горещи точки, където се раздават флаери на ръка. Заповядайте, разгледайте как работи.']
    }
  }, {
    id: 11,
    place: 1,
    pathName: 'broshuri-po-poshtenskite-kutii',
    name: 'Брошури по пощенските кутии',
    image: "https://clients.bgplakat.com/images/1581952069.png",
    iconClassName: 'icon-mailbox',
    description: {
      short: ['Имаме огромен опит в разпространение на брошури наистина. Направо книга можем да напишем по темата. Това е важно, защото от нас категорично не може да се очаква да струпаме брошурите до входа, да натъпчем по 10 брошури в една пощенска кутия или да излъжем, че сме ги раздали.'],
      full: ['Имаме огромен опит в разпространение на брошури наистина. Направо книга можем да напишем по темата. Това е важно, защото от нас категорично не може да се очаква да струпаме брошурите до входа, да натъпчем по 10 брошури в една пощенска кутия или да излъжем, че сме ги раздали.', 'Знаем как да работим с разпространителите на брошури, правим го от 2003 г. Общуваме с тях открито и ги обучаваме стриктно как да вършат тази работа. Също следим как се справят, то се знае. Затова на нас може да се разчита. Но за да не сме голословни, имаме система за отчет. Там се вижда съвсем прозрачно в кой район се разпространяват брошурите и докъде сме стигнали с раздаването. В реално време.', 'През годините изградихме собствена национална мрежа, разделихме градовете на райони и микрорайони с наши очертания, синхронизирани с Google Maps и създадохме и актуализирахме внушителна база данни с прецизна информация за броя на пощенските кутии по райони. Знаем как да раздаваме брошури по пощенските кутии и го правим добре. По-добре от всеки друг!']
    }
  }],
  clientReferences: [{
      companyName: "ПроКредит Банк (България) АД",
      text: "В хода на нашето сътрудничество, екипът на „БГ Плакат” ООД изпълнява качествено и навременно рекламните кампании на “ПроKредит Банк (България)” АД.",
      owner: "Емилия Царева / Марияна Петкова, Изпълнителени директори, ПроКредит Банк (България) АД",
      photoUrl: "https://clients.bgplakat.com/images/1582375392.jpg"
    }, {
      companyName: "„Либра” ЕАД",
      text: "След обстойно проучване на редица предложения от различни куриерски фирми, ние се  спряхме на БГ Плакат, тъй като ни предложиха уникален по вида си за България отчет …",
      owner: "Ваня Чонева, Маркетинг Мениджър, „Либра” ЕАД",
      photoUrl: "https://clients.bgplakat.com/images/1582375232.jpg"
    }, {
      companyName: "„НЕТ ИС САТ” ООД",
      text: "Преди да се свържем с БГ Плакат бяхме готови да се откажем от безадресната реклама, но от както работим с тях, отчитаме едновременно понижение на разходите и по-висок резултат ...",
      owner: "Ясен Младенов, Мениджър крайни клиенти, „НЕТ ИС САТ” ООД",
      photoUrl: "https://clients.bgplakat.com/images/1582375276.jpg"
    }, {
      companyName: "„Интеграл Уърк енд Травъл” ООД",
      text: "Успешната ни съвместна работа ни дава основание да дадем висока оценка за качеството на обслужване, сроковете за изпълнение и отзивчивостта на екипа на БГ Плакат ...",
      owner: "Ана Автова, Мениджър Реклама, „Интеграл Уърк енд Травъл” ООД",
      photoUrl: "https://clients.bgplakat.com/images/1582375218.jpg"
    }, {
      companyName: "„Скай Лайнс” ООД",
      text: "БГ Плакат стриктно и в срок спазва своята част от установените помежду ни договорни отношения ...",
      owner: "Димитър Александров, Управител, „Скай Лайнс” ООД",
      photoUrl: "https://clients.bgplakat.com/images/1582375425.jpg"
    }, {
      companyName: "„Графити ББДО” АД",
      text: "Може да се разчита на БГ Плакат и на тяхната професионална консултация и точност в изпълнение на поетите ангажименти ...",
      owner: "Деница Гочева, Account Director, „Графити ББДО” АД",
      photoUrl: "https://clients.bgplakat.com/images/1582375204.jpg"
    }, {
      companyName: "„ПроАд” ЕООД",
      text: "След внимателно проучване на фирмите занимаващи се с безадресно разпространение, се спряхме на БГ Плакат, тъй като ни впечатлиха с коректното си и честно отношение ...",
      owner: "Антоанета Григорова, Account Executive, „ПроАд” ЕООД",
      photoUrl: "https://clients.bgplakat.com/images/1582375363.jpg"
    }, {
      companyName: "„МКБ Юнионбанк” АД",
      text: "С БГ Плакат, ние сме сигурни, че всеки рекламен материал ще бъде доставен на точното време и на правилното място ...",
      owner: "Росица Димитрова, Ръководител отдел „Маркетинг и комуникации”, „МКБ Юнионбанк” АД",
      photoUrl: "https://clients.bgplakat.com/images/1582375252.jpg"
    }, {
      companyName: "„Ашли Енд Холмс” АД",
      text: "С екипът на БГ Плакат се комуникира лесно ...",
      owner: "Вихра Рогачева, Изпълнителен Директор, „Ашли Енд Холмс” АД",
      photoUrl: "https://clients.bgplakat.com/images/1582375166.jpg"
    }, {
      companyName: "„ОВЕРГАЗ Инк.” АД",
      text: "Можем само да съжаляваме, че направихме толкова трудни и неуспешни опити в безадресната реклама, преди да срещнем БГ Плакат ...",
      owner: "Николай Иванов, Началник отдел “Реклама”, „ОВЕРГАЗ Инк.” АД",
      photoUrl: "https://clients.bgplakat.com/images/1582375291.jpg"
    }, {
      companyName: "„Ролпласт” ЕООД",
      text: "Впечатлени сме от професионализма, гъвкавостта и бързата реакция, но повече от всичко друго, в БГ Плакат ние намерихме един честен и открит партньор, на който може да се разчита ...",
      owner: "Валентин Колев, Маркетинг и реклама, „Ролпласт” ЕООД",
      photoUrl: "https://clients.bgplakat.com/images/1582375410.jpg"
    }, {
      companyName: "„Булсатком” АД",
      text: "Доволни сме от постоянната обратна връзка и адекватна реакция, на всички наши изисквания по време на рекламните ни кампании ...",
      owner: "Иван Енев, Директор реклама, „Булсатком” АД",
      photoUrl: "https://clients.bgplakat.com/images/1582375189.jpg"
    }
  ],
  clientLogos: [
    {
      "companyName": "office express",
      "companyUrl": "https://www.offex.bg/",
      "imageUrl": "https://clients.bgplakat.com/images/1582458865.png"
    },
    {
      "companyName": "libra",
      "companyUrl": "http://www.libra-ag.com/",
      "imageUrl": "https://clients.bgplakat.com/images/1582571701.png"
    },
    {
      "companyName": "procredit",
      "companyUrl": "http://www.procreditbank.bg/",
      "imageUrl": "https://clients.bgplakat.com/images/1582571897.jpg"
    },
    {
      "companyName": "skylines",
      "companyUrl": "http://www.skylines-bg.com/",
      "imageUrl": "https://clients.bgplakat.com/images/1582571938.png"
    },
    {
      "companyName": "usg",
      "companyUrl": "http://www.usg.bg/",
      "imageUrl": "https://clients.bgplakat.com/images/1582572073.png"
    },
    {
      "companyName": "integral",
      "companyUrl": "http://www.integral.bg/",
      "imageUrl": "https://clients.bgplakat.com/images/1582571632.png"
    },
    {
      "companyName": "british_council",
      "companyUrl": "http://www.britishcouncil.org",
      "imageUrl": "https://clients.bgplakat.com/images/1582571358.png"
    },
    {
      "companyName": "gsk",
      "companyUrl": "http://www.gsk.bg/",
      "imageUrl": "https://clients.bgplakat.com/images/1582571566.png"
    },
    {
      "companyName": "motocross",
      "companyUrl": "http://www.bfm.bg/",
      "imageUrl": "https://clients.bgplakat.com/images/1582571787.jpg"
    },
    {
      "companyName": "bulsatcom",
      "companyUrl": "http://www.bulsat.com/",
      "imageUrl": "https://clients.bgplakat.com/images/1582571422.png"
    },
    {
      "companyName": "hyundai",
      "companyUrl": "http://www.hyundaibg.bg/",
      "imageUrl": "https://clients.bgplakat.com/images/1582571590.png"
    },
    {
      "companyName": "yavlena",
      "companyUrl": "http://www.yavlena.com/",
      "imageUrl": "https://clients.bgplakat.com/images/1582572091.png"
    },
    {
      "companyName": "unicredit",
      "companyUrl": "http://www.unicreditbulbank.bg",
      "imageUrl": "https://clients.bgplakat.com/images/1582572042.png"
    },
    {
      "companyName": "raiphaizen",
      "companyUrl": "http://www.rbb.bg/",
      "imageUrl": "https://clients.bgplakat.com/images/1582571910.png"
    },
    {
      "companyName": "tbc",
      "companyUrl": "http://www.tbc.bg/",
      "imageUrl": "https://clients.bgplakat.com/images/1582571981.jpg"
    },
    {
      "companyName": "mtel-new-logo",
      "companyUrl": "http://www.mtel.bg/",
      "imageUrl": "https://clients.bgplakat.com/images/1582571801.jpg"
    },
    {
      "companyName": "mcdonalds",
      "companyUrl": "http://www.mcdonalds.bg/",
      "imageUrl": "https://clients.bgplakat.com/images/1582571745.jpg"
    },
    {
      "companyName": "allianz",
      "companyUrl": "http://www.allianz.bg/",
      "imageUrl": "https://clients.bgplakat.com/images/1582570873.png"
    },
    {
      "companyName": "stemo",
      "companyUrl": "http://www.stemo.bg/",
      "imageUrl": "https://clients.bgplakat.com/images/1582571966.png"
    },
    {
      "companyName": "netissat",
      "companyUrl": "http://www.netissat.bg/",
      "imageUrl": "https://clients.bgplakat.com/images/1582571821.jpg"
    },
    {
      "companyName": "technopolis",
      "companyUrl": "http://www.technopolis.bg/",
      "imageUrl": "https://clients.bgplakat.com/images/1582572000.png"
    },
    {
      "companyName": "overgas",
      "companyUrl": "http://www.overgas.bg/",
      "imageUrl": "https://clients.bgplakat.com/images/1582571847.png"
    },
    {
      "companyName": "multirama",
      "companyUrl": "http://www.multirama.bg/",
      "imageUrl": "https://clients.bgplakat.com/images/1582571813.png"
    },
    {
      "companyName": "picadilly",
      "companyUrl": "http://www.piccadilly.bg/",
      "imageUrl": "https://clients.bgplakat.com/images/1582571864.png"
    },
    {
      "companyName": "toyota",
      "companyUrl": "http://www.toyota.bg/",
      "imageUrl": "https://clients.bgplakat.com/images/1582572017.png"
    },
    {
      "companyName": "cocacola",
      "companyUrl": "http://www.coca-cola.bg/",
      "imageUrl": "https://clients.bgplakat.com/images/1582571445.png"
    },
    {
      "companyName": "donuts",
      "companyUrl": "http://www.dunkindonuts.bg/",
      "imageUrl": "https://clients.bgplakat.com/images/1582571474.png"
    },
    {
      "companyName": "meleto",
      "companyUrl": "http://www.meletofresh.eu/",
      "imageUrl": "https://clients.bgplakat.com/images/1582571764.jpg"
    },
    {
      "companyName": "makmetal",
      "companyUrl": "http://www.makmetal.com/",
      "imageUrl": "https://clients.bgplakat.com/images/1582571721.png"
    },
    {
      "companyName": "lukoil",
      "companyUrl": "http://www.lukoil.bg/",
      "imageUrl": "https://clients.bgplakat.com/images/1582571712.jpg"
    },
    {
      "companyName": "yavor",
      "companyUrl": "http://www.yavor.bg/",
      "imageUrl": "https://clients.bgplakat.com/images/1582572101.png"
    },
    {
      "companyName": "diplomat",
      "companyUrl": "http://www.diplomat.bg/",
      "imageUrl": "https://clients.bgplakat.com/images/1582571464.png"
    },
    {
      "companyName": "pizzahut",
      "companyUrl": "http://www.pizzahut.bg/",
      "imageUrl": "https://clients.bgplakat.com/images/1582571875.png"
    },
    {
      "companyName": "weber",
      "companyUrl": "http://www.weber.bg/",
      "imageUrl": "https://clients.bgplakat.com/images/1582572082.png"
    },
    {
      "companyName": "aub",
      "companyUrl": "http://www.aubg.bg/",
      "imageUrl": "https://clients.bgplakat.com/images/1582571310.png"
    },
    {
      "companyName": "jimmys",
      "companyUrl": "http://www.jimmys.bg/",
      "imageUrl": "https://clients.bgplakat.com/images/1582571656.png"
    },
    {
      "companyName": "aea",
      "companyUrl": "http://www.aea-bg.com/",
      "imageUrl": "https://clients.bgplakat.com/images/1582570629.png"
    },
    {
      "companyName": "happy",
      "companyUrl": "http://www.happy.bg/",
      "imageUrl": "https://clients.bgplakat.com/images/1582571580.png"
    },
    {
      "companyName": "dreamteam",
      "companyUrl": "http://www.komicite.com/",
      "imageUrl": "https://clients.bgplakat.com/images/1582571487.png"
    },
    {
      "companyName": "rollplast",
      "companyUrl": "http://rollplast.com/bg/",
      "imageUrl": "https://clients.bgplakat.com/images/1582571929.png"
    },
    {
      "companyName": "master",
      "companyUrl": "http://www.masterbulgaria.com/",
      "imageUrl": "https://clients.bgplakat.com/images/1582571732.png"
    },
    {
      "companyName": "mkb",
      "companyUrl": "http://www.unionbank.bg/",
      "imageUrl": "https://clients.bgplakat.com/images/1582571774.png"
    },
    {
      "companyName": "allchannels",
      "companyUrl": "http://www.all-channels.com/",
      "imageUrl": "https://clients.bgplakat.com/images/1582570846.png"
    },
    {
      "companyName": "unitedpartners",
      "companyUrl": "http://www.united-partners.com/",
      "imageUrl": "https://clients.bgplakat.com/images/1582572063.png"
    },
    {
      "companyName": "kres",
      "companyUrl": "http://www.kres.com/",
      "imageUrl": "https://clients.bgplakat.com/images/1582571689.png"
    },
    {
      "companyName": "idea",
      "companyUrl": "http://www.idea.bg/",
      "imageUrl": "https://clients.bgplakat.com/images/1582571620.png"
    },
    {
      "companyName": "notto",
      "companyUrl": "http://notto.biz/",
      "imageUrl": "https://clients.bgplakat.com/images/1582571831.png"
    },
    {
      "companyName": "ashley-holmes",
      "companyUrl": "http://www.ashley-holmes.bg/",
      "imageUrl": "https://clients.bgplakat.com/images/1582570988.png"
    },
    {
      "companyName": "primetime",
      "companyUrl": "http://www.primetimebg.com/",
      "imageUrl": "https://clients.bgplakat.com/images/1582571886.png"
    },
    {
      "companyName": "ddb",
      "companyUrl": "http://www.ddb.com/ddbsofia/",
      "imageUrl": "https://clients.bgplakat.com/images/1582571453.png"
    },
    {
      "companyName": "euroRSCG",
      "companyUrl": "http://www.eurorscg.bg/",
      "imageUrl": "https://clients.bgplakat.com/images/1582571522.png"
    },
    {
      "companyName": "graffiti",
      "companyUrl": "http://www.graffiti-bbdo.bg/",
      "imageUrl": "https://clients.bgplakat.com/images/1582571555.png"
    },
    {
      "companyName": "apra",
      "companyUrl": "http://www.apraagency.com/",
      "imageUrl": "https://clients.bgplakat.com/images/1582570902.png"
    },
    {
      "companyName": "tbwa",
      "companyUrl": "http://www.tbwa.com/",
      "imageUrl": "https://clients.bgplakat.com/images/1582571991.png"
    },
    {
      "companyName": "ciela",
      "companyUrl": "http://ciela.bg/",
      "imageUrl": "https://clients.bgplakat.com/images/1582571434.png"
    },
    {
      "companyName": "tucan",
      "companyUrl": "http://www.pizzatoucan.com/",
      "imageUrl": "https://clients.bgplakat.com/images/1582572030.png"
    },
    {
      "companyName": "jeff",
      "companyUrl": "http://jeff.bg/",
      "imageUrl": "https://clients.bgplakat.com/images/1582571647.png"
    },
    {
      "companyName": "atama",
      "companyUrl": "http://atama.bg/",
      "imageUrl": "https://clients.bgplakat.com/images/1582571003.png"
    },
    {
      "companyName": "iberica",
      "companyUrl": "http://www.iberica-bg.com/",
      "imageUrl": "https://clients.bgplakat.com/images/1582571609.png"
    },
    {
      "companyName": "faceTOface",
      "companyUrl": "http://www.facetoface.bg/",
      "imageUrl": "https://clients.bgplakat.com/images/1582571533.png"
    },
    {
      "companyName": "jumena",
      "companyUrl": "http://www.jumena.bg/",
      "imageUrl": "https://clients.bgplakat.com/images/1582571676.png"
    },
    {
      "companyName": "albena",
      "companyUrl": "http://www.albena.bg/",
      "imageUrl": "https://clients.bgplakat.com/images/1582570822.png"
    },
    {
      "companyName": "fresh",
      "companyUrl": "http://radiofresh.bg/",
      "imageUrl": "https://clients.bgplakat.com/images/1582571545.png"
    },
    {
      "companyName": "bnr",
      "companyUrl": "http://www.bnr.bg/",
      "imageUrl": "https://clients.bgplakat.com/images/1582571322.png"
    },
    {
      "companyName": "bta",
      "companyUrl": "http://www.bta.bg/",
      "imageUrl": "https://clients.bgplakat.com/images/1582571412.png"
    },
    {
      "companyName": "sofia",
      "companyUrl": "http://www.sofia.bg/",
      "imageUrl": "https://clients.bgplakat.com/images/1582571949.png"
    }
  ],
  referencesTexts: [{
      id: 2,
      name: 'Цанка Иванова',
      text: 'Много ти благодаря....искам да знаеш нещо....страшно много обичам фирмата ти и всички хора там и въпреки ,че не съм го казала вие сте хората които толкова години ме подкрепяте и когато останах без пръст,с 2000 лв ток вие се появихте....ВИЕ СТЕ МОЕТО СЕМЕЙСТВО'
    }, {
      id: 3,
      name: 'Марио Чилов',
      text: 'Здравейте, аз бях един от дългогодишните служители на фирмата и не съжалявам за това. През всичките години съм бил очарован и приятно изненадан от пълната коректност и добре отношение към мен и колегите ми и не на последно място към клиентите. За БГ Плакат е въпрос на чест, работата да се изпълни качествено и в срок. Тъй като половината от времето се занимавах и с финансите на фирмата, мога спокойно да кажа, че сме били лоялни и във финансово отношение спрямо договорките с конкретната фирма. С една дума бих ги препоръчал.'
    }, {
      id: 4,
      name: 'Надежда Младенова',
      text: 'Решението да започна работа в БГ плакат, беше предизвикателство, тъй като преди това работих в продължение на 10 години за друга фирма. На новото си работно място срещнах прекрасни колеги, като всеки от тях се отличаваше със собствен талант и мечти, но заедно работиха като "добре смазана машина". Впечатли ме - добрата организация, ясните правила и откритите отношения. Ние бяхме екип от личности, чиито мнения се зачитаха и ценяха. Стъпка по стъпка вървяхме напред и всеки помагаше на другия. Най-голямата мотивация за мен, за да давам най-доброто от себе си, беше гласуваното ми доверие. Това ми даваше крила! Бг плакат ми даде много в професионално отношение, но най-вече ми показа,че най-важният ресурс в една фирма са хората! Благодаря!'
    }, {
      id: 5,
      name: 'Силвия Ванкова',
      text: 'В дългите ни години съвместна работа, колегите от БГ Плакат са били коректни и точни. Винаги съм имала безопасна и спокойна работна среда, нещо изключително важно за разкриване потенциала на хората! Благодарности към екипа и управителите!'
    }, {
      id: 6,
      name: 'Георги Ванков',
      text: 'Започнах работа в БГ Плакат без почти никакъв трудов стаж, възможност, която малко фирми биха предоставили. Благодарен съм за това и за опита, който натрупах там.'
    }, {
      id: 7,
      name: 'Александър Василев',
      text: 'Работил съм на много места, но такава колегиална атмосфера, каквато е създадена в „БГ плакат” не срещнах никъде. Няма интригантство, няма клюкарстване, няма кариеризъм стъпвайки по гърбовете на другите. Вместо това има откритост, желание за помощ, разбиране в трудни моменти. Лесно се работи в такъв колектив. А ако има и съвпадение на лични хобита, работата се превръща в незабравимо удоволствие. Незабравими ще останат за мен годините прекарани в „БГ Плакат”.'
    }, {
      id: 8,
      name: 'Боряна Владимирова',
      text: 'Казвам се Боряна и се занимавах като технически администратор в БГ Плакат. Може ли човек да бъде удовлетворен от работата си - това, което върши и хората с които работи? Мисля, че да. В работата работих в тим от хора, имаше към кой да се обърна за помощ, за обучение. Разбрах, че има голяма сила тимовата работа. За мен беше истинско удоволствие да обслужвам различни фирми, така че да бъдат успешни в техния бизнес. Обучението и доброто отношение към мен лично от страна на колеги и ръководители, ми помогнаха да се чувствам добре и да се развивам. И колко много неща научих тук! Страхотни сте били колеги и шефе! Затова: "Дерзайте и останете верни на ценностите, които носите. "Ако мога с една дума да охарактеризирам БГ Плакат то би била "Коректна фирма" към клиентите си и към хората, който работят в нея.'
    }, {
      id: 9,
      name: 'Валерия Кръстева',
      text: 'Здравей Иван. За мен ти остана един от най-коректните ми работодатели. Даже и до днес те препоръчвам на хора, които искат честно да се трудят и развиват и на хора, които търсят бързо и качествено рекламно обслужване. Малко са работодателите днес, за които хората са по-важни от парите. Вярвам в твоя растеж, поради здравата ти ценностна система...'
    }, {
      id: 10,
      name: 'Ники',
      text: 'За мен БГ Плакат винаги ще остане фирмата, която се държи не просто професионално с клиентите, но и супер човечно към служителите си. Попаднах в тази фирма, когато бях в най-ниските нива на моя живот. Бях загубил почти всичко, освен себе си. Въпреки това, всеки от екипа на фирмата се отнасяше с уважение и почит към мен като равен с равен. Дори, когато имах издънки, управителите ми даваха отново и отново шанс. Никога няма да забравя претегната им ръка към мен в онези трудни моменти.'
    },{
      id: 1,
      name: 'Дани Зубев',
      text: 'С БГ Плакат ми се отвориха нови познания в областта на дейността на фирмата, опознах още повече родината, от пътуванията си се запознах с много приятни и позитивни колеги и приятели.'
    }
  ]
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_USER:
      return { ...state, user: action.payload };
    case FETCH_MUSIC:
      return { ...state, music: action.payload };
    case FETCH_POSTS:
      return { ...state, posts: action.payload };
    default:
      return state;
  }
}