import React from 'react';
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import logo from '../assets/images/bgplakat-logo-redesign.svg';
import paperPlain from '../assets/images/paper-plane.svg';

function Footer() {
  const location = useLocation();
  return (
    <footer className="pt-3">
      <Container className="pb-3">
        <Row>
          <Col md={3}>
            <Button variant="link" className="pl-0">
              <Image className="img-logo" src={logo} alt="Logo BGPlakat"></Image>
            </Button>
          </Col>
          <Col md={6}>
            { location.pathname === '/abonament' ?
              null :
              <div className="to-subscribe-newsletter-container">
                <Button as={Link} to="/abonament" className="to-subscribe-newsletter" variant="primary">
                  <Image className="mr-3" src={paperPlain} alt="Subscribe to newsletter"/>
                  Абонирайте се
                </Button>
              </div>
            }
          </Col>
          <Col md={3} className="d-flex align-items-end justify-content-end">
            <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/BG-Plakat-109364593890852">
              <Button variant="" className="brand">
                <FontAwesomeIcon icon={['fab', 'facebook-f']} />
              </Button>
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/bgplakat">
              <Button variant="" className="brand mx-4">
                <FontAwesomeIcon icon={['fab', 'linkedin-in']} />
              </Button>
            </a>
            <Button variant="" className="brand pr-0">
              <FontAwesomeIcon icon={['fab', 'instagram']} />
            </Button>
          </Col>
          <Col xs={12}><hr className="mb-3"/></Col>
          <Col lg={6} className="mb-3 mb-lg-0">
            <Link to="/politika-poveritelnost" className="footer-link d-block text-secondary pl-0 py-0">Политика на поверителност »</Link>
            <Link to="/za-nas/moralen-kodeks" className="footer-link d-block text-secondary pl-0 py-0">Морален кодекс »</Link>
            {/* <Button variant="link" className="footer-link text-primary d-lg-none pl-0 py-0">Към пълната версия на сайта »</Button> */}
          </Col>
          <Col lg={6}>
            <address className="company-address text-secondary text-left text-lg-right mb-0">
              София 1505
              <br></br>
              ул. "Митрополит Панарет" 16
            </address>
          </Col>
        </Row>
      </Container>
      <div className="all-rights-reserved-container py-3">
        <p className="all-rights-reserved text-center text-muted mb-0">2020 © BG PLAKAT | ALL RIGHTS RESERVED</p>
      </div>
    </footer>
  );
}

export default Footer;