import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { getServices } from "../redux/selectors";
import { Helmet } from 'react-helmet';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';

import ModalStartCampaign from '../components/ModalStartCampaign';

function Service({ match, services }) {
  const [ service, setService ] = useState();

  useEffect(() => {
    let matchedService = services.find(service => `/${service.pathName}` === match.path);
    if (matchedService) setService(matchedService);
  }, [match.path, services]);

  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  return (
    <Container className="page-p" id="service">
      <Helmet>
        <title>{`${service ? service.name : ''} | Брошури Плакати Флаери | bgplakat.com`}</title>
        <meta name="description" content={service ? service.description.short[0] : ''} />
        <link rel="canonical" href={`https://www.bgplakat.com/${service ? service.pathName : ''}`} />
      </Helmet>
      <Row className="justify-content-center" noGutters={true}>
        <Col lg={9}>
          <Row>
            <Col lg={4}>
              <div className="service-image-container mt-2 mb-3">
                <Image fluid src={service ? service.image : ''} alt={service ? service.pathName : ''} />
              </div>
            </Col>
            <Col lg={8}>
              <Row className="align-items-end" noGutters={true}>
                <Col lg={4}>
                  <div className="hexagon service-hexagon">
                    <svg role="img" aria-labelledby={`hexagoneTitle hexagoneDesc`} xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="-2 0 304 304" height="100%" xmlnsXlink="http://www.w3.org/1999/xlink">
                      <title id={`hexagoneTitle`}>{`Hexagone ${service ? service.name : ''}`}</title>
                      <desc id={`hexagoneDesc`}>{`Hexagone with ${service ? service.name : ''} service icon`}</desc>
                      <polygon className="hex" points="300,150 225,280 75,280 0,150 75,20 225,20"></polygon>
                    </svg>
                    <span className={"icon " + (service ? service.iconClassName : '')}></span>
                  </div>
                </Col>
                <Col lg={8}>
                  <h1 className="service-title text-primary">{service ? service.name : 'Няма такава услуга'}</h1>
                  <hr/>
                </Col>
              </Row>
              {
                service ? service.description.full.map((text, i) => {
                  return (
                    <p key={`text-${i}`} className="format-paragraph">{text}</p>
                  )
                }) : null
              }
              <Button variant="primary float-right" onClick={handleShowModal}>Вземи оферта</Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <ModalStartCampaign show={showModal} handleClose={handleCloseModal} />
    </Container>
  );
}

const mapStateToProps = state => {
  const services = getServices(state);
  return { services };
};

export default connect(
  mapStateToProps
)(Service);