import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from "react-redux";
import { getClientReferences } from "../redux/selectors";
import Scrollbar from "react-scrollbars-custom";
import { Link } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import LightboxWrapper from '../components/LightboxWrapper';

const ivan = "https://clients.bgplakat.com/images/1581952733.png";
const ivo = "https://clients.bgplakat.com/images/1584086285.png";
const diqn = "https://clients.bgplakat.com/images/1581952817.png";
const niazi = "https://clients.bgplakat.com/images/1582480912.png";
const teodor = "https://clients.bgplakat.com/images/1582552136.png";

function About({ clientReferences }) {
  const [ images ] = useState(clientReferences.map(ref => ref.photoUrl));
  const [ showLightbox, setShowLightbox ] = useState(false);
  const [ photoIndex, setPhotoIndex ] = useState(0);

  const openImage = (e) => {
    setPhotoIndex(e.currentTarget.getAttribute('data-photo-index'));
    setShowLightbox(true);
  }
  const closeImage = (e) => setShowLightbox(false)
  
  return (
    <Container className="page-p" id="about">
      <Helmet>
        <title>БГ Плакат | За нас</title>
        <meta name="description" content="БГ Плакат разпространява листовки и брошури от 2001 г. насам. Имаме прозрачна система за следене. Разгледайте сайта за повече информация." />
        <link rel="canonical" href="https://www.bgplakat.com/za-nas" />
      </Helmet>
      <Row className="justify-content-center">
        <Col lg={9}>
          <h1 className="display-4 text-primary mb-4">За нас</h1>

          <section id="history" className="mb-6">
            <h4 className="text-indent">ИСТОРИЯ</h4>
            <hr></hr>
            <p className="format-paragraph">Действаме още от 2001 г. Обичаме да сме добри. Да правим добро и да го правим добре. Младежките ни грешки в бизнеса вече отдавна са допуснати. Научихме ценни уроци. Изпълнени сме с ентусиазъм да помагаме на други бизнеси да растат.</p>
            <p className="format-paragraph">Обичаме да катерим стени и върхове. Буквално и в преносен смисъл. Силно вярваме в честните отношения в бизнеса. Затова разработихме система за прозрачност. Понеже имахме силно желание да покажем, че вършим добра работа и че на нас може да се разчита.</p>
            <p className="format-paragraph">Но нашето най-значимо преимущество е работата с хора. Умеем да подбираме и организираме най-подходящите. Умеем да правим логистика с хора. Човешки и ефективно.</p>
          </section>

          <section id="who-are-we" className="mb-6">
            <h4 className="text-indent">КОИ СМЕ НИЕ</h4>
            <hr></hr>
            
            <article className="authors mt-4">
              <div className="author-image">
                <svg id="image-fill" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="-2 19 304 304" height="230px" xmlnsXlink="http://www.w3.org/1999/xlink">
                  <defs>
                    <pattern id="image-bg-1" x="0" y="0" height="300" width="300" patternUnits="userSpaceOnUse">
                      <image alt="Author name image" width="100%" height="100%" preserveAspectRatio="xMidYMin meet" xlinkHref={ivan}></image>
                    </pattern>
                  </defs>  
                  <polygon className="hex" points="300,150 225,280 75,280 0,150 75,20 225,20" fill="url('#image-bg-1')"></polygon>
                </svg>
              </div>
              <div className="author-text mt-3">
                <h5 className="author-title text-center text-md-left">Иван Василев</h5>
                <p className="format-paragraph">
                  Близо 20 години заедно с жена ми основахме БГ Плакат. Хората са моята страст - обичам да им помагам да достигнат своя максимален потенциал. А най-щастлив съм, когато използват този потенциал в реална полза за нашите клиенти.
                </p>
              </div>
            </article>

            <article className="authors">
              <div className="author-image">
                <svg id="image-fill" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="-2 19 304 304" height="230px" xmlnsXlink="http://www.w3.org/1999/xlink">
                  <defs>
                    <pattern id="image-bg-2" x="0" y="0" height="300" width="300" patternUnits="userSpaceOnUse">
                      <image alt="Author name image" width="100%" height="100%" preserveAspectRatio="xMidYMin meet" xlinkHref={ivo}></image>
                    </pattern>
                  </defs>  
                  <polygon className="hex" points="300,150 225,280 75,280 0,150 75,20 225,20" fill="url('#image-bg-2')"></polygon>
                </svg>
              </div>
              <div className="author-text mt-3">
                <h5 className="author-title text-center text-md-left">Иво Енчев</h5>
                <p className="format-paragraph">
                  Отговарям за финансите в БГ Плакат. Чувствам се истински удовлетворен, че работя с почтени колеги, за които доверието е висша ценност. Радвам се, че можем да сме част от решението на паричните затруднения на нашите колеги.
                </p>
              </div>
            </article>

            <article className="authors">
              <div className="author-image">
                <svg id="image-fill" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="-2 19 304 304" height="230px" xmlnsXlink="http://www.w3.org/1999/xlink">
                  <defs>
                    <pattern id="image-bg-3" x="0" y="0" height="300" width="300" patternUnits="userSpaceOnUse">
                      <image alt="Author name image" width="100%" height="100%" preserveAspectRatio="xMidYMin meet" xlinkHref={diqn}></image>
                    </pattern>
                  </defs>  
                  <polygon className="hex" points="300,150 225,280 75,280 0,150 75,20 225,20" fill="url('#image-bg-3')"></polygon>
                </svg>
              </div>
              <div className="author-text mt-3">
                <h5 className="author-title text-center text-md-left">Диян Димитров</h5>
                <p className="format-paragraph">
                  Отговарям за планирането на кампаниите на нашите клиенти. За нас детайлите са важни. Затова понякога сме досадно подробни, но резултатът е удовлетворяващ и клиентите го оценяват.
                </p>
              </div>
            </article>

            <article className="authors">
              <div className="author-image">
                <svg id="image-fill" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="-2 19 304 304" height="230px" xmlnsXlink="http://www.w3.org/1999/xlink">
                  <defs>
                    <pattern id="image-bg-4" x="0" y="0" height="300" width="300" patternUnits="userSpaceOnUse">
                      <image alt="Author name image" width="100%" height="100%" preserveAspectRatio="xMidYMin meet" xlinkHref={niazi}></image>
                    </pattern>
                  </defs>  
                  <polygon className="hex" points="300,150 225,280 75,280 0,150 75,20 225,20" fill="url('#image-bg-4')"></polygon>
                </svg>
              </div>
              <div className="author-text mt-3">
                <h5 className="author-title text-center text-md-left">Ниази Мохтасеб</h5>
                <p className="format-paragraph">
                  Аз съм от хората, започнали работа в екипа от най-ниското ниво и стигнах до отговорни позиции като връзка с големите ни клиенти и обучение и контрол на персонала. Радвам се, че мога да помагам и на другите след мен да се развиват по този път.
                </p>
              </div>
            </article>

            <article className="authors">
              <div className="author-image">
                <svg id="image-fill" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="-2 19 304 304" height="230px" xmlnsXlink="http://www.w3.org/1999/xlink">
                  <defs>
                    <pattern id="image-bg-5" x="0" y="0" height="300" width="300" patternUnits="userSpaceOnUse">
                      <image alt="Author name image" width="100%" height="100%" preserveAspectRatio="xMidYMin meet" xlinkHref={teodor}></image>
                    </pattern>
                  </defs>  
                  <polygon className="hex" points="300,150 225,280 75,280 0,150 75,20 225,20" fill="url('#image-bg-5')"></polygon>
                </svg>
              </div>
              <div className="author-text mt-3">
                <h5 className="author-title text-center text-md-left">Теодор Георгиев</h5>
                <p className="format-paragraph">
                  Щастлив съм, че съм част от този екип вече пета година, грижите за хората с които работим и лоялността към клиента не престават да ме радват. Вярвам, че най-доброто тепърва предстои!
                </p>
              </div>
            </article>

            <article className="mb-6">
              <Link to="/za-nas/chesti-vaprosi-za-nas/">
                <h4 className="text-indent">Чести въпроси за нас</h4>
              </Link>
            </article>
          </section>

          <section id="references" className="mb-6">
            <h4 className="text-indent">РЕФЕРЕНЦИИ</h4>
            <hr></hr>
            <div className="articles-container mt-4 mCustomScrollbar" data-mcs-theme="dark-thick">
              <Scrollbar style={{ width: "auto", height: 300 }}>
                {
                  clientReferences.map((ref, index) => {
                    return (
                      <article key={index}>
                        <p className="format-paragraph font-italic mb-2">{ref.text}</p>
                        <p className="font-italic text-right mb-2">{ref.owner}</p>
                        <p className="text-right"><Button variant="link" onClick={openImage} data-photo-index={index}>цялата референция »</Button></p>
                      </article>
                    )
                  })
                }
              </Scrollbar>
            </div>
          </section>
        </Col>
      </Row>
      {
        showLightbox && <LightboxWrapper images={images} photoIndex={photoIndex} onClose={closeImage} /> 
      }
    </Container>
  );
}

const mapStateToProps = state => {
  const clientReferences = getClientReferences(state);
  return { clientReferences };
};

export default connect(
  mapStateToProps
)(About);