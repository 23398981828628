import React from 'react';
import { connect } from "react-redux";
import { getUser } from "../redux/selectors";
import { fetchUser } from "../redux/actions";
import ls from 'local-storage';

import Dropdown from 'react-bootstrap/Dropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function UserStatusBar({ user, fetchUser }) {
  const handleLogout = (e) => {
    fetchUser(null);
    ls.remove('bgplakatUser');
  }

  return (
    <div className="user-login-status">
      { user ?
        <div className="d-inline-block">
          <span>Добре дошъл, </span>
          <span className="font-italic">{user.name}</span>
          <span>!</span>
        </div> : null
      }
      <Dropdown className="d-inline-block">
        <Dropdown.Toggle className="btn-user-login" variant="" id="dropdown-basic">
          <FontAwesomeIcon icon={['fas', 'user-circle']} />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          { user ?
            <Dropdown.Item onClick={handleLogout}>Изход</Dropdown.Item>
            :
            <Dropdown.Item href="/vhod">Вход</Dropdown.Item>
          }
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

const mapStateToProps = state => {
  const user = getUser(state);
  return { user };
};

export default connect(
  mapStateToProps,
  { fetchUser }
)(UserStatusBar);